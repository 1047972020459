import React, { useEffect, useState } from "react";
import { AppBaseUrl, BaseUrl, ImageBaseUrl } from "../BaseUrl";
import axios from "axios";
import toast from "react-hot-toast";
import Modal from "react-modal";
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "fixed",
    },
};
const ShopDet = ({ shopData, id, getUserDetails }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState({});
    const [categories, setCategories] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");

    const openModal = (image) => {
        setModalImage(image);
        setModalIsOpen(true);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };

    const getCategories = (filt, page = 1) => {
        const config = {
            url: `${AppBaseUrl}/advertising/categories`,
            method: "GET",
        };
        axios(config)
            .then((res) => {
                setCategories(res?.data?.categories);
            })
            .catch((err) => {});
    };
    useEffect(() => {
        getCategories();
    }, []);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    console.log(editedData);

    const handleSave = async () => {
        try {
            const response = await axios.put(
                `${BaseUrl}/shops/update?shopId=${shopData._id}`,
                editedData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`, // اگر نیاز به توکن احراز هویت دارید
                    },
                }
            );
            getUserDetails();
            setIsEditing(false);
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.errors
            ) {
                error.response.data.errors.forEach((err) => {
                    if (typeof err.message === "string") {
                        // نمایش پیام خطا در صورتیکه به صورت string باشد
                        toast.error(err.message);
                    } else {
                        // نمایش پیام خطا برای موارد دیگر
                        toast.error("خطای ناشناخته");
                    }
                });
            } else {
                // نمایش پیام خطا برای سایر موارد
                toast.error("خطای ناشناخته");
            }
        }
    };
    const contactInfoArray = shopData?.contactInfo
        ? Object.keys(shopData.contactInfo).map((key) => {
              let title;
              switch (key) {
                  case "phone":
                      title = "شماره ثابت";
                      break;
                  case "mobile":
                      title = "شماره همراه";
                      break;
                  case "telegramLink":
                      title = "لینک تلگرام";
                      break;
                  case "whatsappNumber":
                      title = "شماره واتساپ";
                      break;
                  case "instagramLink":
                      title = "لینک اینستاگرام";
                      break;
                  case "saveInfoForNextAds":
                      title = "ذخیره اطلاعات برای آگهی‌های بعدی";
                      break;
                  default:
                      title = key;
              }
              return { title, value: shopData.contactInfo[key] };
          })
        : [];
    return (
        <div className="flex gap-3  ">
            <div className="flex flex-col gap-5 pr-5 mt-5">
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    className="post-image-modal"
                    style={customStyles}
                >
                    {/* استفاده از ImageMagnify */}
                    <img
                        src={modalImage}
                        alt="Post"
                        className="post-modal-image"
                    />
                </Modal>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="#00838F"
                            d="M12.12 13.53h-.17c-2.27-.07-3.97-1.84-3.97-4.02 0-2.22 1.81-4.03 4.03-4.03s4.03 1.81 4.03 4.03a4.014 4.014 0 01-3.89 4.02h-.03zM12 6.97c-1.4 0-2.53 1.14-2.53 2.53a2.52 2.52 0 002.43 2.53.84.84 0 01.23 0 2.535 2.535 0 002.4-2.53c0-1.39-1.13-2.53-2.53-2.53zM12 22.75c-2.69 0-5.26-1-7.25-2.82a.755.755 0 01-.24-.63c.13-1.19.87-2.3 2.1-3.12 2.98-1.98 7.81-1.98 10.78 0 1.23.83 1.97 1.93 2.1 3.12.03.24-.06.47-.24.63A10.71 10.71 0 0112 22.75zM6.08 19.1A9.208 9.208 0 0012 21.25c2.17 0 4.26-.76 5.92-2.15-.18-.61-.66-1.2-1.37-1.68-2.46-1.64-6.63-1.64-9.11 0-.71.48-1.18 1.07-1.36 1.68z"
                        ></path>
                        <path
                            fill="#00838F"
                            d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12S6.07 1.25 12 1.25 22.75 6.07 22.75 12 17.93 22.75 12 22.75zm0-20C6.9 2.75 2.75 6.9 2.75 12S6.9 21.25 12 21.25s9.25-4.15 9.25-9.25S17.1 2.75 12 2.75z"
                        ></path>
                    </svg> */}
                        تصویر پروفایل:
                    </div>
                    <div className="flex items-center font-medium  font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 p-4">
                        <img
                            src={ImageBaseUrl + shopData?.profile_image}
                            className="w-[200px] h-[200px] rounded-md"
                            alt=""
                        />
                    </div>
                </div>
                {/* User Name */}
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        نام کاربری:
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {shopData?.user_name}
                    </div>
                </div>

                {/* First Name */}
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        نام:
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {shopData?.first_name}
                    </div>
                </div>

                {/* Last Name */}
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        نام خانوادگی:
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {shopData?.last_name}
                    </div>
                </div>

                {/* Mobile */}
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        دسته بندی:
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {isEditing ? (
                            <select
                                defaultValue={shopData.category}
                                className="bg-[#222] text-primary px-2 py-1 rounded-md w-full min-h-9 text-white"
                                onChange={handleChange}
                                name="category"
                                id="category"
                            >
                                {categories &&
                                    categories.map((item) => {
                                        return (
                                            <option value={item?.title}>
                                                {item?.title}
                                            </option>
                                        );
                                    })}
                            </select>
                        ) : (
                            shopData?.category
                        )}
                    </div>
                </div>
                {/* National Code */}
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        عنوان فروشگاه:
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {isEditing ? (
                            <input
                                type="text"
                                name="vitrine_name"
                                defaultValue={shopData.vitrine_name}
                                onChange={handleChange}
                                className="bg-[#222] text-primary px-2 py-1 rounded-md w-full min-h-9"
                            />
                        ) : (
                            shopData?.vitrine_name
                        )}
                    </div>
                </div>

                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        استان:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {shopData?.province?.name}
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        شهر:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {shopData?.city?.name}
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        محله:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {shopData?.neighbourhood}
                    </div>
                </div>
                {/* Address */}
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        آدرس:
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {isEditing ? (
                            <input
                                type="text"
                                name="address"
                                defaultValue={shopData.address}
                                onChange={handleChange}
                                className="bg-[#222] text-primary px-2 py-1 rounded-md w-full min-h-9"
                            />
                        ) : (
                            shopData?.address
                        )}
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        لیست خدمات و کالا
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4 py-4">
                        <div>
                            {shopData?.allServices &&
                                shopData?.allServices?.map((item) => {
                                    return (
                                        <div className="mt-5">
                                            <img
                                                onClick={() => {
                                                    openModal(
                                                        ImageBaseUrl +
                                                            item?.image
                                                    );
                                                }}
                                                src={ImageBaseUrl + item?.image}
                                                className="w-[100px] h-[100px] rounded-sm"
                                                alt=""
                                            />
                                            <p className="mt-2">
                                                نام : {item?.title}
                                            </p>
                                            <p className="mt-2">
                                                درصد تخفیف :{" "}
                                                {item?.discountPercentage}
                                            </p>
                                            <p className="mt-2">
                                                قیمت اصلی :{" "}
                                                {item?.originalPrice}
                                            </p>
                                            <p className="mt-2">
                                                قیمت تخفیف خورده :{" "}
                                                {item?.discountPrice}
                                            </p>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        لیست امکانات
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4 py-4">
                        <div>
                            {shopData?.allFeatures &&
                                shopData?.allFeatures?.map((item) => {
                                    return (
                                        <div className="mt-5 flex">
                                            <p className="mt-2">
                                                {" "}
                                                {item?.title} :{" "}
                                            </p>
                                            <p className="mt-2 mr-5">
                                                {item?.value == true
                                                    ? "دارد"
                                                    : "ندارد"}
                                            </p>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        لیست تماس
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4 py-4">
                        <div>
                            {contactInfoArray.map((item, index) => (
                                <div className="mt-5 flex" key={index}>
                                    <p className="mt-2"> {item.title} : </p>
                                    <p className="mt-2 mr-5">{item.value}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        تصاویر
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4 py-4 flex-wrap">
                        {shopData?.allImages &&
                            shopData?.allImages?.map((item) => {
                                return (
                                    <div>
                                        <img
                                            onClick={() => {
                                                openModal(ImageBaseUrl + item);
                                            }}
                                            src={ImageBaseUrl + item}
                                            className="w-[100px] h-[100px] rounded-sm m-4"
                                            alt=""
                                        />
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        درباره ما:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {isEditing ? (
                            <input
                                type="text"
                                name="about_us"
                                defaultValue={shopData.about_us}
                                onChange={handleChange}
                                className="bg-[#222] text-primary px-2 py-1 rounded-md w-full min-h-9"
                            />
                        ) : (
                            shopData?.about_us
                        )}
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        تاریخ ثبت فروشگاه:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {shopData?.createdAt}
                    </div>
                </div>

                {/* Save Button */}
                <div className="flex items-center w-full">
                    {isEditing ? (
                        <button
                            onClick={handleSave}
                            className="bg-[#146820] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300"
                        >
                            ذخیره
                        </button>
                    ) : (
                        <button
                            onClick={() => setIsEditing(!isEditing)}
                            className="bg-[#146820] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-3"
                        >
                            ویرایش
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ShopDet;
