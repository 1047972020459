import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import Loading from "../components/elements/loading";
import { useAuth } from "../utils/AuthProvider";
import axios from "axios";
import { BaseUrl } from "../components/BaseUrl";
import toast, { useToaster } from "react-hot-toast";

const Login = () => {
    const navigate = useNavigate();
    const [loading, setloading] = useState(false);
    const token = useAuth();
    const [userName, setUserName] = useState("")
    const [password, setPassword] = useState("")
    useEffect(() => {
        if (token !== null) {
            navigate("/", { replace: true });
        }
    }, []);
    const loginHandler = () => {
        const data = {
            user_name: userName,
            password,
        };
        axios
            .post(`${BaseUrl}/login`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then(async (response) => {
                try {
                    localStorage.setItem("token", response?.data?.token);
                    document.getElementById("to_dashboard").click();
                } catch (error) {
                    toast.error("اطلاعات وارد شده صحیح نمیباشد");
                }
            })
            .catch(function (error) {
                toast.error(error?.response?.data?.message);
            });
    }
    function refreshPage() {
        setTimeout(() => {
            window.location.reload(false);
        }, 1000);
    }
    return (
        <div className="bg-[#259b9b] relative min-h-screen">
            <Link
                to={{ pathname: "/" }}
                id="to_dashboard"
                onClick={refreshPage}
            ></Link>
            <div className="mx-auto  w-full max-w-[500px] min-h-screen flex justify-center relative z-20 items-center font-medium font-KalamehMed">
                <div className=" p-12 rounded-xl w-full">
                    <div className="text-black py-12 rounded-xl flex bg-white flex-col justify-center items-center text[##030303] px-10">
                        <h5 className="text-black text-2xl font-bold">
                            ورود
                        </h5>
                        <div
                            className="w-full pt-5"
                        >
                            <div className="w-full py-3 flex flex-col">
                                <label
                                    htmlFor="user_name"
                                    className="text-right"
                                >
                                    نام کاربری:
                                </label>
                                <input
                                    type="text"
                                    x-model="input3"
                                    className="appearance-none dir-ltr text-left px-3 bg-blue-lightt text-black rounded-xl mt-1 w-full h-11 border-solid border-[1px] border-[#CBCBCB] focus:outline-none font-KalamehMed placeholder:text-[#C4C7C7]"
                                    value={userName}
                                    onChange={e => setUserName(e.target.value)}
                                />
                            </div>
                            <div className="w-full  flex flex-col">
                                <label
                                    htmlFor="password"
                                    className=" text-right"
                                >
                                    کلمه عبور:
                                </label>
                                <input
                                    type="password"
                                    x-model="input3"
                                    className="appearance-none dir-ltr px-3 bg-blue-lightt text-black rounded-xl mt-1 w-full h-11 text-left border-solid border-[1px] border-[#CBCBCB] focus:outline-none font-KalamehMed placeholder:text-[#C4C7C7]"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                />
                            </div>
                            <div className="w-full py-3 pt-10 flex justify-center">
                                <button
                                    onClick={() => loginHandler()}
                                    className="text-white bg-[#259b9b] flex justify-center items-center rounded-xl w-full h-11"
                                >
                                    {loading ? (
                                        <Loading className="w-10 h-10 text-blacklead animate-pulse" />
                                    ) : (
                                        "ورود"
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
