import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import DashboardIcon from "../assets/icons/dashboardIcon";
import UsersIcon from "../assets/icons/UsersIcon";
import PostsIcon from "../assets/icons/PostsIcon";
import ProjectsIcon from "../assets/icons/ProjectsIcon";
import SupportIcon from "../assets/icons/SupportIcon";
import FinancialsIcon from "../assets/icons/FinancialsIcon";
import CommentsIcon from "../assets/icons/CommentsIcon";
import AdvertisingIcon from "../assets/icons/AdvertisingIcon";
import ShopIcon from "../assets/icons/ShopIcon";

function Tooltip({ svgIcon, title }) {
    const [showTooltip, setshowTooltip] = useState(false);
    const handleShowTooltip = () => {
        setshowTooltip(true);
    };
    const handleHideTooltip = () => {
        setshowTooltip(false);
    };
    return (
        <div>
            <div className="flex items-center justify-center relative ">
                <span
                    className="z-50 cursor-pointer"
                    onMouseEnter={() => handleShowTooltip()}
                    onMouseLeave={() => handleHideTooltip()}
                >
                    {svgIcon}
                </span>
                <div
                    className={`relative transition-all  z-30 -mt-8 inline-flex ${
                        showTooltip ? "flex" : "hidden"
                    }`}
                >
                    <div className="relative">
                        <div className="absolute font-Kalameh top-0 z-10  p-1 -mt-1 flex items-center justify-center text-xs leading-tight text-white transform translate-x-3/4 -translate-y-full bg-gray-500  rounded-lg shadow-lg">
                            {title}
                        </div>
                        <svg
                            className="absolute z-10 w-6 h-6 text-gray-500 transform translate-x-5 -translate-y-3 fill-current stroke-current"
                            width="8"
                            height="8"
                        >
                            <rect
                                x="12"
                                y="-10"
                                width="8"
                                height="8"
                                transform="rotate(45)"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    );
}

// import dashboard from '../../assets/images/icons/setting-3.png'

const Sidebar = () => {
    const { pathname } = useLocation();

    const handleLogOut = () => {
        localStorage.clear();
        document.getElementById("to_login").click();
    };
    let param = useParams();
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    useEffect(() => {
        setItems([
            {
                menu: "داشبورد",
                subshow: false,
                link: "/",
                submenu: [],
                icon(pathnamin) {
                    return (
                        <Tooltip
                            svgIcon={
                                <DashboardIcon
                                    className={`  group-hover:fill-[#1f5f79] ${
                                        (this.subshow ||
                                            pathnamin === this.link) &&
                                        !pathnamin.includes("/orders")
                                            ? "fill-[#1f5f79]"
                                            : "fill-white"
                                    }`}
                                />
                            }
                            title="داشبورد"
                        />
                    );
                },
            },
            {
                menu: "کاربران",
                link: "/users",
                subshow: false,
                submenu: [],
                icon(pathnamin) {
                    return (
                        <Tooltip
                            svgIcon={
                                <UsersIcon
                                    className={`  group-hover:fill-[#1f5f79] ${
                                        (this.subshow ||
                                            pathnamin === this.link) &&
                                        !pathnamin.includes("/orders")
                                            ? "fill-[#1f5f79]"
                                            : "fill-white"
                                    }`}
                                />
                            }
                            title="کاربران"
                        />
                    );
                },
            },
            {
                menu: "پست ها",
                link: "/posts",
                subshow: false,
                submenu: [],
                icon(pathnamin) {
                    return (
                        <Tooltip
                            svgIcon={
                                <PostsIcon
                                    className={`  group-hover:fill-[#1f5f79] ${
                                        (this.subshow ||
                                            pathnamin === this.link) &&
                                        !pathnamin.includes("/orders")
                                            ? "fill-[#1f5f79]"
                                            : "fill-white"
                                    }`}
                                />
                            }
                            title="پست ها"
                        />
                    );
                },
            },
            {
                menu: "پروژه ها",
                link: "/projects",
                subshow: false,
                submenu: [],
                icon(pathnamin) {
                    return (
                        <Tooltip
                            svgIcon={
                                <ProjectsIcon
                                    className={`  group-hover:fill-[#1f5f79] ${
                                        (this.subshow ||
                                            pathnamin === this.link) &&
                                        !pathnamin.includes("/orders")
                                            ? "fill-[#1f5f79]"
                                            : "fill-white"
                                    }`}
                                />
                            }
                            title="پروژه ها"
                        />
                    );
                },
            },
            {
                menu: "تیکت ها",
                link: "/tickets",
                subshow: false,
                submenu: [],
                icon(pathnamin) {
                    return (
                        <Tooltip
                            svgIcon={
                                <SupportIcon
                                    className={`  group-hover:fill-[#1f5f79] ${
                                        (this.subshow ||
                                            pathnamin === this.link) &&
                                        !pathnamin.includes("/orders")
                                            ? "fill-[#1f5f79]"
                                            : "fill-white"
                                    }`}
                                />
                            }
                            title="تیکت ها"
                        />
                    );
                },
            },
            {
                menu: "مالی",
                link: "/financials",
                subshow: false,
                submenu: [],
                icon(pathnamin) {
                    return (
                        <Tooltip
                            svgIcon={
                                <FinancialsIcon
                                    className={`  group-hover:fill-[#1f5f79] ${
                                        (this.subshow ||
                                            pathnamin === this.link) &&
                                        !pathnamin.includes("/orders")
                                            ? "fill-[#1f5f79]"
                                            : "fill-white"
                                    }`}
                                />
                            }
                            title="مالی"
                        />
                    );
                },
            },
            {
                menu: "نظرات",
                link: "/comments",
                subshow: false,
                submenu: [],
                icon(pathnamin) {
                    return (
                        <Tooltip
                            svgIcon={
                                <CommentsIcon
                                    className={`  group-hover:fill-[#1f5f79] ${
                                        (this.subshow ||
                                            pathnamin === this.link) &&
                                        !pathnamin.includes("/orders")
                                            ? "fill-[#1f5f79]"
                                            : "fill-white"
                                    }`}
                                />
                            }
                            title="نظرات"
                        />
                    );
                },
            },
            {
                menu: "ویترین",
                link: "/vitrine",
                subshow: false,
                submenu: [],
                icon(pathnamin) {
                    return (
                        <Tooltip
                            svgIcon={
                                <AdvertisingIcon
                                    className={`  group-hover:fill-[#1f5f79] `}
                                />
                            }
                            title="ویترین"
                        />
                    );
                },
            },
            {
                menu: "نظرات ویترین",
                link: "/vitrine-comments",
                subshow: false,
                submenu: [],
                icon(pathnamin) {
                    return (
                        <Tooltip
                            svgIcon={
                                <AdvertisingIcon
                                    className={`  group-hover:fill-[#1f5f79] `}
                                />
                            }
                            title="نظرات ویترین"
                        />
                    );
                },
            },
            {
                menu: "فروشگاه",
                link: "/shops",
                subshow: false,
                submenu: [],
                icon(pathnamin) {
                    return (
                        <Tooltip
                            svgIcon={
                                <ShopIcon
                                    className={`  group-hover:fill-[#1f5f79] ${
                                        (this.subshow ||
                                            pathnamin === this.link) &&
                                        !pathnamin.includes("/orders")
                                            ? "fill-[#1f5f79]"
                                            : "fill-white"
                                    }`}
                                />
                            }
                            title="نظرات ویترین"
                        />
                    );
                },
            },
            {
                menu: "قوانین مقررات",
                link: "/terms",
                subshow: false,
                submenu: [],
                icon(pathnamin) {
                    return (
                        <Tooltip
                            svgIcon={
                                <ShopIcon
                                    className={`  group-hover:fill-[#1f5f79] ${
                                        (this.subshow ||
                                            pathnamin === this.link) &&
                                        !pathnamin.includes("/orders")
                                            ? "fill-[#1f5f79]"
                                            : "fill-white"
                                    }`}
                                />
                            }
                            title="قوانین مقررات"
                        />
                    );
                },
            },
            {
                menu: "خروج",
                subshow: false,
                link: "#",
                submenu: [],
                icon(pathnamin) {
                    return (
                        <Tooltip
                            svgIcon={
                                <button
                                    // onClick={() => logOutMutation.mutate()}
                                    class="flex items-center group rounded-r-lg transition duration-[400ms] outline-none "
                                >
                                    <svg
                                        className={`  group-hover:fill-blacklead ${
                                            this.subshow ||
                                            pathnamin
                                                .replace("/", "")
                                                .includes(
                                                    this?.link.replace("/", "")
                                                )
                                                ? "fill-blacklead"
                                                : "fill-white"
                                        }`}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="25"
                                        fill="none"
                                        viewBox="0 0 24 25"
                                    >
                                        <path d="M17.44 15.87c-.19 0-.38-.07-.53-.22a.754.754 0 010-1.06l2.03-2.03-2.03-2.03a.754.754 0 010-1.06c.29-.29.77-.29 1.06 0l2.56 2.56c.29.29.29.77 0 1.06l-2.56 2.56c-.15.15-.34.22-.53.22z"></path>
                                        <path d="M19.93 13.31H9.76c-.41 0-.75-.34-.75-.75s.34-.75.75-.75h10.17c.41 0 .75.34.75.75s-.34.75-.75.75z"></path>
                                        <path d="M11.76 21.25c-5.15 0-8.75-3.6-8.75-8.75s3.6-8.75 8.75-8.75c.41 0 .75.34.75.75s-.34.75-.75.75c-4.27 0-7.25 2.98-7.25 7.25s2.98 7.25 7.25 7.25c.41 0 .75.34.75.75s-.34.75-.75.75z"></path>
                                    </svg>
                                </button>
                            }
                            title="خروج"
                        />
                    );
                },
            },
        ]);
    }, []);
    // this one make submenu stay opened if its in one of its submenu pages onrefreshes
    useEffect(() => {
        let indexOfItem = 0;
        for (let item of items) {
            indexOfItem++;
            for (let submenu of item?.submenu) {
                if (
                    pathname
                        .replace("/", "")
                        .includes(submenu?.link.replace("/", ""))
                ) {
                    setItems((prev) =>
                        [...prev, (prev[indexOfItem - 1].subshow = true)].slice(
                            0,
                            -1
                        )
                    );
                    return;
                }
            }
        }
    }, []);

    const navigateToMenu = (item) => {
        navigate(item?.link);
        setItems((prev) =>
            prev.map((sidebarItem) => {
                return { ...sidebarItem, subshow: false };
            })
        );
    };
    const showSubMenu = (item, index) => {
        // navigate(item?.submenu[0]?.link);
        setItems((prev) =>
            prev.map((sidebarItem, i) =>
                i === index
                    ? { ...sidebarItem, subshow: !sidebarItem?.subshow }
                    : { ...sidebarItem, subshow: false }
            )
        );
    };
    function refreshPage() {
        setTimeout(() => {
            window.location.reload(false);
        }, 1000);
    }
    console.log(pathname.replaceAll("/", ""));
    return (
        <div className="sticky top-[0] w-[200px] bg-[#1f5f79] items-start h-[calc(100vh)] font-KalamehMed font-medium overflow-y-scroll hide-scrollbar flex flex-col  p-3 pt-10 text-center">
            <Link
                to={{ pathname: "/login" }}
                id="to_login"
                onClick={refreshPage}
            ></Link>
            <div className="w-full">
                {items.map((item, index) => (
                    <div className="py-0.5 w-full flex flex-col">
                        {item?.submenu?.length === 0 ? (
                            <button
                                onClick={() => navigateToMenu(item)}
                                className={`flex p-2 my-2 items-center hover:bg-white group rounded-lg transition duration-[400ms] outline-none ${
                                    item?.link.replace("/", "") !== ""
                                        ? pathname
                                              .replace("/", "")
                                              .includes(
                                                  item?.link.replace("/", "")
                                              )
                                            ? "bg-white hover:bg-[#BBDEE1] transition duration-[400ms] font-KalamehSemi font-semibold"
                                            : ""
                                        : pathname.replace("/", "") ==
                                              item?.link.replace("/", "") &&
                                          pathname
                                              .replace("/", "")
                                              .includes(
                                                  item?.link.replace("/", "")
                                              )
                                        ? "bg-white hover:bg-[#BBDEE1] transition duration-[400ms] font-KalamehSemi font-semibold"
                                        : ""
                                }`}
                            >
                                {item?.icon(pathname)}
                                <span
                                    className={` group-hover:text-blacklead  pr-2 ${
                                        pathname
                                            .replace("/", "")
                                            .includes(
                                                item?.link.replace("/", "")
                                            ) &&
                                        item?.link.replace("/", "") ==
                                            pathname.replace("/", "")
                                            ? "text-blacklead"
                                            : "text-white"
                                    }`}
                                >
                                    {item?.menu}
                                </span>
                            </button>
                        ) : (
                            <button
                                onClick={() => showSubMenu(item, index)}
                                className={`flex p-2 my-2 items-center w-full hover:bg-white group rounded-r-lg transition duration-[400ms] outline-none ${
                                    item?.subshow
                                        ? "bg-white hover:bg-[#BBDEE1] transition duration-[400ms]  font-KalamehSemi font-semibold"
                                        : ""
                                }`}
                            >
                                {item?.icon(pathname)}
                                <span
                                    className={` group-hover:text-blacklead  ${
                                        item?.subshow
                                            ? "text-blacklead"
                                            : "text-white"
                                    }`}
                                >
                                    {item?.menu}
                                </span>
                            </button>
                        )}

                        <div
                            className={`pr-4 w-full ${
                                item?.subshow
                                    ? "max-h-[800px] overflow-hidden transition-all ease-linear duration-[400ms]"
                                    : "max-h-0 overflow-hidden transition-all ease-linear duration-[400ms] "
                            }`}
                        >
                            <div className="flex flex-col w-full border-r border-cyann-700 pr-2 mt-2">
                                {item?.submenu?.map((submenuItem) => {
                                    var hovered = false;
                                    var p = pathname.split("/");
                                    var s = submenuItem?.link?.split("/");

                                    if (p.length == s.length) {
                                        if (p[1] == s[1]) {
                                            if (
                                                Object.keys(param).length > 1 &&
                                                param.constructor === Object
                                            ) {
                                                hovered = true;
                                            } else {
                                                if (
                                                    p.every(
                                                        (v, i) => v === s[i]
                                                    )
                                                ) {
                                                    hovered = true;
                                                }
                                            }
                                        }
                                    } else {
                                        if (
                                            p[1] == s[1] &&
                                            p[1] != "products"
                                        ) {
                                            hovered = true;
                                        }
                                    }

                                    return (
                                        <Link
                                            to={submenuItem?.link}
                                            className={`flex my-0.5 h-11 items-center pr-2 pl-[1.14rem] rounded-r-lg hover:bg-cyann hover:text-white w-full transition duration-[400ms] ${
                                                hovered
                                                    ? " text-white bg-cyann hover:bg-opacity-70 transition duration-[400ms]"
                                                    : "text-cyann-700"
                                            }  `}
                                        >
                                            {submenuItem?.menu}
                                        </Link>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Sidebar;
