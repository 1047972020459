import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/admin-header";
import Sidebar from "../../components/sidebar";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../../components/BaseUrl";
import axios from "axios";
import Loading from "../../components/elements/loading";

const VitrineComments = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [commentList, setCommentList] = useState([]);
    const getCommentList = (filt, page = 1) => {
        setLoading(true);
        const config = {
            url: `${BaseUrl}/advertising/comments?limit=20&page=${page}`,
            method: "GET",
        };
        axios(config)
            .then((res) => {
                setCommentList(res?.data?.comments);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getCommentList();
    }, []);
    return (
        <>
            <AdminHeader />
            <div className="min-h-screen mx-auto">
                <div className="flex h-full w-full">
                    <div className="bg-[#1f5f79]">
                        <Sidebar />
                    </div>
                    <div className="w-full h-full p-4 mb-11 mx-auto bg-[#252525] text-white ">
                        <div className="w-full mt-4 flex items-center bg-[#252525] border-b ">
                            <div className="flex-1 w-full flex items-center h-11 px-5">
                                <div className="col-span-1 w-[13%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        عنوان ویترین
                                    </p>
                                </div>
                                <div className="col-span-1  w-[13%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        نام کاربری سازنده
                                    </p>
                                </div>
                                <div className="col-span-1  w-[13%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        نام و نام خانوادگی سازنده
                                    </p>
                                </div>
                                <div className="col-span-1  w-[13%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        نام کاربری یوزر
                                    </p>
                                </div>
                                <div className="col-span-1  w-[13%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        نام و نام خانوادگی یوزر
                                    </p>
                                </div>
                                <div className="col-span-1  w-[13%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        تاریخ ثبت کامنت
                                    </p>
                                </div>
                                <div className="col-span-1  w-[12%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        وضعیت
                                    </p>
                                </div>
                                <div className="col-span-1 w-[11%]">
                                    <p className="font-KalamehMed text-sm font-medium"></p>
                                </div>
                            </div>
                        </div>
                        {loading ? (
                            <div className="w-full flex items-center justify-center mt-5">
                                <Loading className="w-14 h-14 text-blacklead animate-pulse" />
                            </div>
                        ) : (
                            <div
                                className="overflow-y-scroll hide-scrollbar w-full"
                            // style={{ maxHeight: maxHeightTable }}
                            >
                                {commentList?.map((comment, index) => (
                                    <div className="w-full mt-4 flex items-center bg-[#252525]">
                                        <div
                                            className={`flex-1 w-full flex items-center h-[90px] px-5  rounded-lg  py-8 ${index % 2 == 0
                                                ? "bg-[#c0e2f04b]"
                                                : "bg-[#678fa062]"
                                                }`}
                                        >
                                            <div className="col-span-1 w-[13%]">
                                                <p className="font-KalamehMed text-sm font-medium mr-2">
                                                    {comment?.advertising}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[13%]">
                                                <p className="font-KalamehMed text-sm font-medium mr-2">
                                                    {comment?.creator?.user_name}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[13%]">
                                                <p className="font-KalamehMed text-sm font-medium mr-2">
                                                    {comment?.creator?.first_name}
                                                    {" "}
                                                    {comment?.creator?.last_name}

                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[13%]">
                                                <p className="font-KalamehMed text-sm font-medium mr-2">
                                                    {comment?.user?.user_name}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[13%]">
                                                <p className="font-KalamehMed text-sm font-medium mr-2">
                                                    {comment?.user?.first_name}
                                                    {" "}
                                                    {comment?.user?.last_name}

                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[13%]">
                                                <p className="font-KalamehMed text-sm font-medium mr-2">
                                                    {comment?.createdAt}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[12%]">
                                                <div className="flex items-center">
                                                    <p className={`font-KalamehMed text-sm font-medium ${comment?.status
                                                        === "pending" ?
                                                        "text-yellow-400" :
                                                        comment?.status == "accepted" ?
                                                            "text-green-400" :
                                                            "text-red-400"}
                                        `}>
                                                        {comment?.status
                                                            === "pending" ?
                                                            "در انتظار تایید" :
                                                            comment?.status == "accepted" ?
                                                                "تایید شده" :
                                                                "رد شده"
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-span-1 w-[11%]">
                                                <p className="font-KalamehMed text-sm font-medium">
                                                    <button
                                                        onClick={() => {
                                                            navigate(
                                                                `${comment?._id}`,
                                                            );
                                                        }}
                                                        className="bg-[#1f5f79] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-5"
                                                    >
                                                        جزئیات
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div >
        </>
    );
};

export default VitrineComments;
