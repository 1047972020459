import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AdminHeader from "../../components/admin-header";
import Sidebar from "../../components/sidebar";
import axios from "axios";
import { BaseUrl } from "../../components/BaseUrl";
import toast from "react-hot-toast";

const TermsAndConditionsEditor = () => {
    const [editorContent, setEditorContent] = useState("");

    useEffect(() => {
        // دریافت قوانین و مقررات موجود از سرور در بارگذاری اولیه
        axios
            .get(`${BaseUrl}/settings/terms-and-conditions`)
            .then((response) => {
                setEditorContent(response.data.value);
            })
            .catch((error) => {
                console.error("Error fetching terms and conditions:", error);
            });
    }, []);

    const handleSave = () => {
        axios
            .put(`${BaseUrl}/settings/terms-and-conditions`, { value: editorContent }, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then((response) => {
                    toast.success(response?.data?.message)
                // نمایش پیغام موفقیت (در صورت نیاز از toast یا هر کتابخانه مشابه دیگر استفاده کنید)
            })
            .catch((error) => {
                console.error("Error updating terms and conditions:", error);
                // نمایش پیغام خطا (در صورت نیاز از toast یا هر کتابخانه مشابه دیگر استفاده کنید)
            });
    };
    return (
        <>
            <AdminHeader />
            <div className="min-h-screen mx-auto flex flex-col">
                <div className="flex flex-1 h-full w-full">
                    <div className="bg-[#1f5f79]">
                        <Sidebar />
                    </div>
                    <div className="w-full h-full p-4 mb-11 mx-auto bg-[#252525] text-white relative">
                        <h1>ویرایشگر قوانین و مقررات</h1>
                        <ReactQuill
                            value={editorContent}
                            onChange={setEditorContent}
                            modules={TermsAndConditionsEditor.modules}
                            formats={TermsAndConditionsEditor.formats}
                            placeholder="متن خود را اینجا وارد کنید..."
                            className="min-h-[500px] mt-5"
                        />
                        <button
                            onClick={handleSave}
                            className="w-[200px] bg-green-700 py-2 rounded-lg"
                        >
                            ذخیره
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
};

TermsAndConditionsEditor.modules = {
    toolbar: [
        [
            { header: "1" },
            { header: "2" },
            { header: "3" },
            { header: "4" },
            { header: "5" },
            { header: "6" },
            { font: [] },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [{ align: [] }],
        [{ color: [] }, { background: [] }],
        ["link"],
        ["clean"],
    ],
};

TermsAndConditionsEditor.formats = [
    "header",
    "font",
    "list",
    "bullet",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "align",
    "color",
    "background",
    "link",
];

export default TermsAndConditionsEditor;
