import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './App';
import toast from 'react-hot-toast';
import axios from 'axios';
import { BaseUrl } from './components/BaseUrl';

const root = ReactDOM.createRoot(document.getElementById('root'));

const handleUnauthorized = () => {
  localStorage.removeItem("userData");
  localStorage.removeItem("token");
  localStorage.removeItem("login");
  window.location = "/login";
};

const getToken = () => {
  const token = localStorage.getItem("token");
  return token == null ? "" : token;
};
axios.interceptors.request.use(
  (config) => {
    if (!config.url.endsWith('/panel/login')) {
      config.headers["Authorization"] = `Bearer ${getToken()}`;
    }
      return config;
  },
  (error) => {
      return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
      if (response.data.message && response.data.message != "")
          toast.success(response.data.message);
      return response;
  },
  (error) => {
      if (error.response.status == 422) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((err) =>
              error.response.data.errors[err].map((item) => toast.error(item))
          );
        } 

      } else if (error.response.status == 401) {
          handleUnauthorized();
      } else if (
          error.response.status == 429 ||
          error.response.status == 500 ||
          error.response.status == 503
      ) {
          toast.error("خطایی رخ داده");
      } else if (error.response.status == 403) {
          // window.location.reload();
          toast.error(
              "متاسفانه دسترسی شما به بخش هایی از این صفحه محدود شده است"
          );
      } else {
          toast.error(error.response.data.message);
      }

      return Promise.reject(error);
  }
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

