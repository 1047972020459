import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from './AuthProvider';

const ProtectedRoute = ({ children }) => {
  const token = useAuth();
  const navigate = useNavigate();
 
  useEffect(() => {
    if (!token) {
      navigate('/login', { replace: true });
    }
  }, [navigate, token]);

  return children;
};

export default ProtectedRoute;
