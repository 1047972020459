import React from "react";

function Loading(props) {
    return (
        <>
            <svg
                width="91px"
                height="91px"
                viewBox="0 0 100 100"
                preserveAspectRatio="xMidYMid"
                {...props}
            >
                <circle
                    cx="50"
                    cy="50"
                    r="37"
                    stroke-width="5"
                    stroke="#4FB3BF80"
                    stroke-dasharray="58.119464091411174 58.119464091411174"
                    fill="none"
                    stroke-linecap="round"
                >
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        dur="1.408450704225352s"
                        repeatCount="indefinite"
                        keyTimes="0;1"
                        values="0 50 50;360 50 50"
                    />
                </circle>
                <circle
                    cx="50"
                    cy="50"
                    r="31"
                    stroke-width="5"
                    stroke="#4FB3BF"
                    stroke-dasharray="48.69468613064179 48.69468613064179"
                    stroke-dashoffset="48.69468613064179"
                    fill="none"
                    stroke-linecap="round"
                >
                    <animateTransform
                        attributeName="transform"
                        type="rotate"
                        dur="1.408450704225352s"
                        repeatCount="indefinite"
                        keyTimes="0;1"
                        values="0 50 50;-360 50 50"
                    />
                </circle>
            </svg>
        </>
    );
}

export default Loading;
