import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/admin-header";
import Sidebar from "../../components/sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { BaseUrl, ImageBaseUrl } from "../../components/BaseUrl";
import axios from "axios";
import Loading from "../../components/elements/loading";
import toast from "react-hot-toast";
import Modal from "react-modal";

const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "fixed",
    },
};
const PostDetail = () => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param?.id;
    const [loading, setLoading] = useState(false);
    const [postDetails, setPostDetails] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");

    const openModal = (image) => {
        setModalImage(image);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const getPostDetails = (filt, page = 1) => {
        setLoading(true);
        const config = {
            url: `${BaseUrl}/posts/detail?post_id=${id}`,
            method: "GET",
        };
        axios(config)
            .then((res) => {
                setPostDetails(res?.data?.post);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getPostDetails();
    }, [id]);
    const togglePostAccept = (_id, user_id, status) => {
        const data = {
            id: _id,
            user_id,
            status: status,
        };
        axios
            .post(`${BaseUrl}/posts/accept`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then(async (response) => {
                try {
                    toast.success(response?.data?.message);
                    getPostDetails();
                } catch (error) {
                    if (error?.response?.data?.error) {
                        toast.error(error.response.data.error);
                    } else {
                        // نمایش پیام خطا برای سایر موارد
                        toast.error("خطای ناشناخته");
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                toast.show(error?.response?.data?.message, {
                    type: "danger",
                    placement: "top",
                    duration: 4000,
                    offset: 30,
                    animationType: "slide-in",
                });
            });
    };
    return (
        <>
            <AdminHeader />
            <div className="min-h-screen mx-auto">
                <div className="flex h-full w-full">
                    <div className="bg-[#1f5f79]">
                        <Sidebar />
                    </div>
                    <div className="w-full h-full p-4 mb-11 mx-auto bg-[#252525] text-white ">
                        <div className="w-full flex items-center justify-between">
                            <h4 className="font-medium font-KalamehMed text-lg">
                                اطلاعات پست:
                            </h4>
                            <button
                                onClick={() => navigate(-1)}
                                className="flex gap-1  bg-[#1f5f79] font-semibold font-KalamehSemi rounded-lg hover:bg-[#E0E3E3] transition-colors duration-500 p-[10px] "
                            >
                                بازگشت
                                <svg
                                    width="24"
                                    height="25"
                                    viewBox="0 0 24 25"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M9.56994 19.3191C9.37994 19.3191 9.18994 19.2491 9.03994 19.0991L2.96994 13.0291C2.67994 12.7391 2.67994 12.2591 2.96994 11.9691L9.03994 5.89914C9.32994 5.60914 9.80994 5.60914 10.0999 5.89914C10.3899 6.18914 10.3899 6.66914 10.0999 6.95914L4.55994 12.4991L10.0999 18.0391C10.3899 18.3291 10.3899 18.8091 10.0999 19.0991C9.95994 19.2491 9.75994 19.3191 9.56994 19.3191Z"
                                        fill="#fff"
                                    />
                                    <path
                                        d="M20.4999 13.25H3.66992C3.25992 13.25 2.91992 12.91 2.91992 12.5C2.91992 12.09 3.25992 11.75 3.66992 11.75H20.4999C20.9099 11.75 21.2499 12.09 21.2499 12.5C21.2499 12.91 20.9099 13.25 20.4999 13.25Z"
                                        fill="#fff"
                                    />
                                </svg>
                            </button>
                        </div>
                        {loading ? (
                            <div className="w-full flex items-center justify-center mt-5">
                                <Loading className="w-14 h-14 text-blacklead animate-pulse" />
                            </div>
                        ) : (
                            <div className="flex gap-3  ">
                                <div className="flex flex-col gap-5 pr-5 mt-5">
                                    <Modal
                                        isOpen={modalIsOpen}
                                        onRequestClose={closeModal}
                                        className="post-image-modal"
                                        style={customStyles}
                                    >
                                        {/* استفاده از ImageMagnify */}
                                        <img
                                            src={modalImage}
                                            alt="Post"
                                            className="post-modal-image"
                                        />
                                    </Modal>
                                    <div className="flex items-center w-full">
                                        <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                            تصویر پروفایل :
                                        </div>
                                        <div className="flex items-center font-medium  font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 p-4">
                                            <img
                                                src={
                                                    ImageBaseUrl +
                                                    postDetails?.profile_image
                                                }
                                                className="w-[100px] h-[100px] rounded-md"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="flex items-center w-full">
                                        <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                            نام کاربری:
                                        </div>
                                        <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                            {postDetails?.user_name}
                                        </div>
                                    </div>
                                    <div className="flex items-center w-full">
                                        <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                            نام و نام خانوادگی:
                                        </div>
                                        <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                            {postDetails?.first_name}{" "}
                                            {postDetails?.last_name}
                                        </div>
                                    </div>
                                    <div className="flex items-center w-full">
                                        <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                            زمان ثبت پست:
                                        </div>
                                        <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                            {postDetails?.createdAt}
                                        </div>
                                    </div>
                                    <div className="flex items-center w-full">
                                        <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                            وضعیت:
                                        </div>
                                        <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                            <div className="flex items-center">
                                                <p
                                                    className={`font-KalamehMed text-sm font-medium ${
                                                        postDetails?.status ===
                                                        "accept"
                                                            ? "text-green-300"
                                                            : postDetails?.status ===
                                                              "pending"
                                                            ? "text-yellow-300"
                                                            : "text-red-300"
                                                    }`}
                                                >
                                                    {postDetails?.status ===
                                                    "accept"
                                                        ? "منتشر شده"
                                                        : postDetails?.status ===
                                                          "pending"
                                                        ? "در انتظار تایید"
                                                        : "رد شده"}
                                                </p>
                                                <button
                                                    onClick={() =>
                                                        togglePostAccept(
                                                            postDetails?._id,
                                                            postDetails?.user_id,
                                                            "reject"
                                                        )
                                                    }
                                                    className="bg-[#a02121d3] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-3"
                                                >
                                                    رد کردن
                                                </button>
                                                <button
                                                    onClick={() =>
                                                        togglePostAccept(
                                                            postDetails?._id,
                                                            postDetails?.user_id,
                                                            "accept"
                                                        )
                                                    }
                                                    className="bg-[#146820] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-3"
                                                >
                                                    انتشار
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex items-center w-full">
                                        <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                            سطح کاربر:
                                        </div>
                                        <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                            {postDetails?.user_level}
                                        </div>
                                    </div>
                                    <div className="flex items-center w-full">
                                        <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                            تصویر پست :
                                        </div>
                                        <div className="flex items-center font-medium  font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 p-4">
                                            <img
                                                onClick={() => {
                                                    openModal(
                                                        ImageBaseUrl +
                                                            postDetails?.post_image
                                                    );
                                                }}
                                                src={
                                                    ImageBaseUrl +
                                                    postDetails?.post_image
                                                }
                                                className="w-[200px] h-[200px] rounded-md"
                                                alt=""
                                            />
                                        </div>
                                    </div>
                                    <div className="flex items-center w-full">
                                        <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                            کپشن:
                                        </div>
                                        <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4 py-4">
                                            {postDetails?.caption}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default PostDetail;
