import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { BaseUrl, ImageBaseUrl } from '../../components/BaseUrl';
import axios from 'axios';
import AdminHeader from '../../components/admin-header';
import Sidebar from '../../components/sidebar';
import Loading from '../../components/elements/loading';
import toast from 'react-hot-toast';
import Modal from 'react-modal';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: "fixed",
    },
};
const VitrineDetail = () => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param?.id;
    const [loading, setLoading] = useState(false);
    const [vitrineDetails, setVitrineDetails] = useState([]);
    const [editingVitrine, setEditingVitrine] = useState(false);
    const [newVitrine, setNewVitrine] = useState('');
    const [rejectReason, setRejectReason] = useState("")
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalImage, setModalImage] = useState('');

    const openModal = (image) => {
        setModalImage(image);
        setModalIsOpen(true);
    }
    const closeModal = () => {
        setModalIsOpen(false);
    }
    const getVitrineDetails = (filt, page = 1) => {
        setLoading(true);
        const config = {
            url: `${BaseUrl}/advertising/detail?advertisingId=${id}`,
            method: "GET",
        };
        axios(config)
            .then((res) => {
                setVitrineDetails(res?.data?.advertising);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getVitrineDetails();
    }, [id]);

    const acceptVitrine = () => {
        const data = {
            advertisingId: vitrineDetails?._id,
        };
        axios
            .post(`${BaseUrl}/advertising/accept`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then(async (response) => {
                try {
                    toast.success(response?.data?.message)
                    getVitrineDetails()
                } catch (error) {
                    if (
                        error?.response?.data?.error
                    ) {
                        toast.error(error.response.data.error);
                    } else {
                        // نمایش پیام خطا برای سایر موارد
                        toast.error("خطای ناشناخته");
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                toast.show(error?.response?.data?.message, {
                    type: "danger",
                    placement: "top",
                    duration: 4000,
                    offset: 30,
                    animationType: "slide-in",
                });
            });
    };

    const rejectVitrine = () => {
        const data = {
            advertisingId: vitrineDetails?._id,
            rejectReason: rejectReason,
        };
        axios
            .post(`${BaseUrl}/advertising/reject`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then(async (response) => {
                try {
                    toast.success(response?.data?.message)
                    getVitrineDetails()
                } catch (error) {
                    if (
                        error?.response?.data?.error
                    ) {
                        toast.error(error.response.data.error);
                    } else {
                        // نمایش پیام خطا برای سایر موارد
                        toast.error("خطای ناشناخته");
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                toast.show(error?.response?.data?.message, {
                    type: "danger",
                    placement: "top",
                    duration: 4000,
                    offset: 30,
                    animationType: "slide-in",
                });
            });
    };

    const handleEditVitrine = () => {
        // ارسال درخواست برای ویرایش ویترین به سرور
        // سپس بروزرسانی متغیر مربوط به متن ویترین
        setEditingVitrine(true);
    };



    const contactInfoArray = vitrineDetails?.contactInfo ? Object.keys(vitrineDetails.contactInfo).map(key => {
        let title;
        switch (key) {
            case "phone":
                title = "شماره ثابت";
                break;
            case "mobile":
                title = "شماره همراه";
                break;
            case "telegramLink":
                title = "لینک تلگرام";
                break;
            case "whatsappNumber":
                title = "شماره واتساپ";
                break;
            case "instagramLink":
                title = "لینک اینستاگرام";
                break;
            case "saveInfoForNextAds":
                title = "ذخیره اطلاعات برای آگهی‌های بعدی";
                break;
            default:
                title = key;
        }
        return { title, value: vitrineDetails.contactInfo[key] };
    }) : [];

    return (<>
        {loading && <Loading />}
        {!loading && vitrineDetails && (
            <>
                <AdminHeader />
                <div className="min-h-screen mx-auto">
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        className="post-image-modal"
                        style={customStyles}
                    >
                        {/* استفاده از ImageMagnify */}
                        <img src={modalImage} alt="Post" className="post-modal-image" />
                    </Modal>
                    <div className="flex h-full w-full">
                        <Sidebar />
                        <div className="w-full h-full p-4 mb-11 mx-auto bg-[#252525] text-white ">
                            <div className="w-full flex items-center justify-between">
                                <h4 className="font-medium font-KalamehMed text-lg">
                                    اطلاعات ویترین:
                                </h4>
                                <button
                                    onClick={() => navigate(-1)}
                                    className="flex gap-1 bg-[#1f5f79] font-semibold font-KalamehSemi rounded-lg hover:bg-[#E0E3E3] transition-colors duration-500 p-[10px]"
                                >
                                    بازگشت
                                </button>
                            </div>
                            <div className="flex flex-col gap-5 pr-5 mt-5">
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        عنوان ویترین:
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        {vitrineDetails?.title}
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        نام و نام خانوادگی سازنده:
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        {vitrineDetails?.creator_id?.first_name} {vitrineDetails?.creator_id?.last_name}
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        نام کاربری کاربر:
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        {vitrineDetails?.creator_id?.user_name}
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        دسته بندی
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        {vitrineDetails?.category}
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        استان
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        {vitrineDetails?.province?.name}
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        شهر
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        {vitrineDetails?.city?.name}
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        محله
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        {vitrineDetails?.neighbourhood}
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        لیست خدمات و کالا
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4 py-4">
                                        <div>
                                            {vitrineDetails?.services && vitrineDetails?.services?.map(item => {
                                                return (<div className='mt-5'>
                                                    <img
                                                        onClick={() => { openModal(ImageBaseUrl + item?.image) }}
                                                        src={ImageBaseUrl + item?.image} className='w-[100px] h-[100px] rounded-sm' alt="" />
                                                    <p className='mt-2'>نام : {item?.title}</p>
                                                    <p className='mt-2'>درصد تخفیف : {item?.discountPercentage}</p>
                                                    <p className='mt-2'>قیمت اصلی : {item?.originalPrice}</p>
                                                    <p className='mt-2'>قیمت تخفیف خورده : {item?.discountPrice}</p>
                                                </div>)
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        لیست امکانات
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4 py-4">
                                        <div>
                                            {vitrineDetails?.features && vitrineDetails?.features?.map(item => {
                                                return (<div className='mt-5 flex'>
                                                    <p className='mt-2'> {item?.title}  : </p>
                                                    <p className='mt-2 mr-5'>{item?.value == true ? "دارد" : "ندارد"}</p>
                                                </div>)
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        لیست تماس
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4 py-4">
                                        <div>
                                            {contactInfoArray.map((item, index) => (
                                                <div className='mt-5 flex' key={index}>
                                                    <p className='mt-2'> {item.title}  : </p>
                                                    <p className='mt-2 mr-5'>{item.value}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        تصاویر
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4 py-4">
                                        {vitrineDetails?.images && vitrineDetails?.images?.map(item => {
                                            return (<div>
                                                <img
                                                    onClick={() => { openModal(ImageBaseUrl + item) }}
                                                    src={ImageBaseUrl + item} className='w-[100px] h-[100px] rounded-sm mx-4' alt="" />
                                            </div>)
                                        })}
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        توضیحات
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        {vitrineDetails?.description}
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        وضعیت:
                                    </div>
                                    <div className={`flex items-center font-medium min-w-[500px] font-KalamehMed text-sm  rounded-lg bg-[#111] min-h-14 px-4 ${vitrineDetails?.status
                                        === "pending" ?
                                        "text-yellow-400" :
                                        vitrineDetails?.status == "accepted" ?
                                            "text-green-400" :
                                            "text-red-400"
                                        }`}>
                                        <p className={`font-KalamehMed text-sm font-medium ${vitrineDetails?.status
                                            === "pre_payment" ?
                                            "text-yellow-400" :
                                            vitrineDetails?.status == "paid" ?
                                                "text-green-400" : vitrineDetails?.status == "accepted" ?
                                                    "text-green-400" :
                                                    "text-red-400"}
                                        `}>
                                            {vitrineDetails?.status
                                                === "pre_payment" ?
                                                "در انتظار پرداخت" :
                                                vitrineDetails?.status == "paid" ?
                                                    "پرداخت شده" : vitrineDetails?.status == "accepted" ?
                                                        "تایید شده" : vitrineDetails?.status == "rejected" ?
                                                            "رد شده" :
                                                            "منقضی شده"
                                            }
                                        </p>
                                    </div>
                                </div>
                                {vitrineDetails?.reject_reason &&

                                    <div className="flex items-center w-full">
                                        <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                            علت رد
                                        </div>
                                        <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                            {vitrineDetails?.reject_reason}
                                        </div>
                                    </div>}
                            </div>
                            <div className="flex items-center gap-4 mt-5">
                                {!editingVitrine ? (
                                    <>
                                        {/* <button
                                            onClick={handleEditVitrine}
                                            className="bg-green-500 text-white px-3 py-1 rounded-lg"
                                        >
                                            ویرایش نظر
                                        </button> */}
                                        <button
                                            onClick={acceptVitrine}
                                            className="bg-blue-500 text-white px-3 py-1 rounded-lg"
                                        >
                                            قبول ویترین
                                        </button>
                                        <div className="flex flex-col mr-[100px] items-center">
                                            <textarea
                                                className="bg-gray-100 rounded-lg p-2 w-[500px] text-black"
                                                placeholder='دلیل رد ویترین'
                                                name="" id="" onChange={e => setRejectReason(e.target.value)} value={rejectReason} ></textarea>
                                            <button
                                                onClick={rejectVitrine}
                                                className="bg-red-500 mt-5 w-[120px] text-white px-3 py-1 rounded-lg"
                                            >
                                                رد ویترین
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <textarea
                                            value={newVitrine}
                                            onChange={(e) => setNewVitrine(e.target.value)}
                                            className="bg-gray-100 rounded-lg p-2"
                                        />
                                        <button
                                            onClick={() => setEditingVitrine(false)}
                                            className="bg-green-500 text-white px-3 py-1 rounded-lg"
                                        >
                                            ذخیره تغییرات
                                        </button>
                                        <button
                                            onClick={() => setEditingVitrine(false)}
                                            className="bg-red-500 text-white px-3 py-1 rounded-lg"
                                        >
                                            انصراف
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
        }
    </>
    )
}
export default VitrineDetail