import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { BaseUrl } from '../../components/BaseUrl';
import axios from 'axios';
import AdminHeader from '../../components/admin-header';
import Sidebar from '../../components/sidebar';
import Loading from '../../components/elements/loading';
import toast from 'react-hot-toast';

const VitrineCommentDetail = () => {
    const navigate = useNavigate();
    const param = useParams();
    const id = param?.id;
    const [loading, setLoading] = useState(false);
    const [commentDetails, setCommentDetails] = useState([]);
    const [commentStatus, setCommentStatus] = useState('');
    const [editingComment, setEditingComment] = useState(false);
    const [newComment, setNewComment] = useState('');
    const getCommentDetails = (filt, page = 1) => {
        setLoading(true);
        const config = {
            url: `${BaseUrl}/advertising/comments/detail?comment_id=${id}`,
            method: "GET",
        };
        axios(config)
            .then((res) => {
                setCommentDetails(res?.data?.comment);
                setLoading(false);
                setNewComment(res?.data?.comment?.text)
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getCommentDetails();
    }, [id]);

    const acceptComment = () => {
        const data = {
            id: commentDetails?._id,
            status: "accepted",
            text: newComment
        };
        axios
            .post(`${BaseUrl}/advertising/comments/accept`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then(async (response) => {
                try {
                    toast.success(response?.data?.message)
                    getCommentDetails()
                } catch (error) {
                    if (
                        error?.response?.data?.error
                    ) {
                        toast.error(error.response.data.error);
                    } else {
                        // نمایش پیام خطا برای سایر موارد
                        toast.error("خطای ناشناخته");
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                toast.show(error?.response?.data?.message, {
                    type: "danger",
                    placement: "top",
                    duration: 4000,
                    offset: 30,
                    animationType: "slide-in",
                });
            });
    };

    const rejectComment = () => {
        const data = {
            id: commentDetails?._id,
            status: "rejected"
        };
        axios
            .post(`${BaseUrl}/advertising/comments/accept`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then(async (response) => {
                try {
                    toast.success(response?.data?.message)
                    getCommentDetails()
                } catch (error) {
                    if (
                        error?.response?.data?.error
                    ) {
                        toast.error(error.response.data.error);
                    } else {
                        // نمایش پیام خطا برای سایر موارد
                        toast.error("خطای ناشناخته");
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                toast.show(error?.response?.data?.message, {
                    type: "danger",
                    placement: "top",
                    duration: 4000,
                    offset: 30,
                    animationType: "slide-in",
                });
            });
    };

    const handleEditComment = () => {
        // ارسال درخواست برای ویرایش کامنت به سرور
        // سپس بروزرسانی متغیر مربوط به متن کامنت
        setEditingComment(true);
    };
    return (<>
        {loading && <Loading />}
        {!loading && commentDetails && (
            <>
                <AdminHeader />
                <div className="min-h-screen mx-auto">
                    <div className="flex h-full w-full">
                        <Sidebar />
                        <div className="w-full h-full p-4 mb-11 mx-auto bg-[#252525] text-white ">
                            <div className="w-full flex items-center justify-between">
                                <h4 className="font-medium font-KalamehMed text-lg">
                                    اطلاعات کامنت:
                                </h4>
                                <button
                                    onClick={() => navigate(-1)}
                                    className="flex gap-1 bg-[#1f5f79] font-semibold font-KalamehSemi rounded-lg hover:bg-[#E0E3E3] transition-colors duration-500 p-[10px]"
                                >
                                    بازگشت
                                </button>
                            </div>
                            <div className="flex flex-col gap-5 pr-5 mt-5">
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        عنوان ویترین:
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        {commentDetails?.advertising}
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        نام کاربری سازنده:
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        {commentDetails?.creator?.user_name}
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        نام و نام خانوادگی سازنده:
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        {commentDetails?.creator?.first_name} {commentDetails?.creator?.last_name}
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        نام کاربری کاربر:
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        {commentDetails?.user?.user_name}
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        نام و نام خانوادگی کاربر:
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        {commentDetails?.user?.first_name} {commentDetails?.user?.last_name}
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        تاریخ ثبت کامنت:
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        {commentDetails?.createdAt}
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        متن کامنت:
                                    </div>
                                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        {commentDetails?.text}
                                    </div>
                                </div>
                                <div className="flex items-center w-full">
                                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                                        وضعیت:
                                    </div>
                                    <div className={`flex items-center font-medium min-w-[500px] font-KalamehMed text-sm  rounded-lg bg-[#111] min-h-14 px-4 ${commentDetails?.status
                                        === "pending" ?
                                        "text-yellow-400" :
                                        commentDetails?.status == "accepted" ?
                                            "text-green-400" :
                                            "text-red-400"
                                        }`}>
                                        {commentDetails?.status
                                            === "pending" ?
                                            "در انتظار تایید" :
                                            commentDetails?.status == "accepted" ?
                                                "تایید شده" :
                                                "رد شده"
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center gap-4 mt-5">
                                {!editingComment ? (
                                    <>
                                        <button
                                            onClick={handleEditComment}
                                            className="bg-green-500 text-white px-3 py-1 rounded-lg"
                                        >
                                            ویرایش نظر
                                        </button>
                                        <button
                                            onClick={acceptComment}
                                            className="bg-blue-500 text-white px-3 py-1 rounded-lg"
                                        >
                                            قبول کامنت
                                        </button>
                                        <button
                                            onClick={rejectComment}
                                            className="bg-red-500 text-white px-3 py-1 rounded-lg"
                                        >
                                            رد کامنت
                                        </button>
                                    </>
                                ) : (
                                    <>
                                        <div className='flex flex-col'>
                                            <textarea
                                                value={newComment}
                                                onChange={(e) => setNewComment(e.target.value)}
                                                className="bg-gray-100 text-black rounded-lg p-2 w-[300px] h-[200px] mb-3"
                                            />
                                            <button
                                                onClick={() => {
                                                    setEditingComment(false)
                                                    acceptComment()
                                                }} className="bg-green-500 text-white px-3 py-1 rounded-lg"
                                            >
                                                ذخیره تغییرات
                                            </button>
                                        </div>
                                        <button
                                            onClick={() => setEditingComment(false)}
                                            className="bg-red-500 text-white px-3 py-1 rounded-lg"
                                        >
                                            انصراف
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
        }
    </>
    )
}
export default VitrineCommentDetail