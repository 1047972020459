import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/admin-header";
import Sidebar from "../../components/sidebar";
import { useNavigate } from "react-router-dom";
import { BaseUrl, ImageBaseUrl } from "../../components/BaseUrl";
import axios from "axios";
import Loading from "../../components/elements/loading";
import toast from "react-hot-toast";

const Projects = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [projectsList, setProjectsList] = useState([]);
    const getProjectList = (filt, page = 1) => {
        setLoading(true);
        const config = {
            url: `${BaseUrl}/projects?limit=20&page=${page}`,
            method: "GET",
        };
        axios(config)
            .then((res) => {
                setProjectsList(res?.data?.projects);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getProjectList();
    }, []);
    const togglePostAccept = (_id, user_id, status) => {
        const data = {
            id: _id,
            user_id,
            status: status == "accept" ? "reject" : "accept",
        };
        axios
            .post(`${BaseUrl}/projects/accept`, data, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then(async (response) => {
                try {
                    toast.success(response?.data?.message);
                    getProjectList();
                } catch (error) {
                    if (error?.response?.data?.error) {
                        toast.error(error.response.data.error);
                    } else {
                        // نمایش پیام خطا برای سایر موارد
                        toast.error("خطای ناشناخته");
                    }
                }
            })
            .catch((error) => {
                console.error(error);
                toast.show(error?.response?.data?.message, {
                    type: "danger",
                    placement: "top",
                    duration: 4000,
                    offset: 30,
                    animationType: "slide-in",
                });
            });
    };
    return (
        <>
            <AdminHeader />
            <div className="min-h-screen mx-auto">
                <div className="flex h-full w-full">
                    <div className="bg-[#1f5f79]">
                        <Sidebar />
                    </div>
                    <div className="w-full h-full p-4 mb-11 mx-auto bg-[#252525] text-white ">
                        <div className="w-full mt-4 flex items-center bg-[#252525] border-b ">
                            <div className="flex-1 w-full flex items-center h-11 px-5">
                                <div className="col-span-1 w-[10%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        عنوان پروژه
                                    </p>
                                </div>
                                <div className="col-span-1 w-[8%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        کارفرما
                                    </p>
                                </div>
                                <div className="col-span-1  w-[8%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        کاربر
                                    </p>
                                </div>
                                <div className="col-span-1  w-[12%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        تخصص
                                    </p>
                                </div>
                                <div className="col-span-1  w-[8%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        زمان پیشنهادی
                                    </p>
                                </div>
                                <div className="col-span-1  w-[8%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        زمان نهایی
                                    </p>
                                </div>
                                <div className="col-span-1  w-[8%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        قیمت پیشنهادی
                                    </p>
                                </div>
                                <div className="col-span-1  w-[8%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        قیمت نهایی
                                    </p>
                                </div>
                                <div className="col-span-1  w-[12%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        تاریخ ثبت درخواست
                                    </p>
                                </div>
                                <div className="col-span-1  w-[12%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        نوع پروژه
                                    </p>
                                </div>
                                <div className="col-span-1 w-[8%]">
                                    <p className="font-KalamehMed text-sm font-medium"></p>
                                </div>
                            </div>
                        </div>
                        {loading ? (
                            <div className="w-full flex items-center justify-center mt-5">
                                <Loading className="w-14 h-14 text-blacklead animate-pulse" />
                            </div>
                        ) : (
                            <div
                                className="overflow-y-scroll hide-scrollbar w-full"
                                // style={{ maxHeight: maxHeightTable }}
                            >
                                {projectsList?.map((project, index) => (
                                    <div className="w-full mt-4 flex items-center bg-[#252525]">
                                        <div
                                            className={`flex-1 w-full flex items-center h-[90px] px-5  rounded-lg  py-8 ${
                                                index % 2 == 0
                                                    ? "bg-[#c0e2f04b]"
                                                    : "bg-[#678fa062]"
                                            }`}
                                        >
                                            <div className="col-span-1 w-[10%]">
                                                <div className="flex items-center">
                                                    {project?.title}
                                                </div>
                                            </div>
                                            <div className="col-span-1 w-[8%]">
                                                <p className="font-KalamehMed text-sm font-medium">
                                                    {
                                                        project?.creator
                                                            ?.first_name
                                                    }{" "}
                                                    {
                                                        project?.creator
                                                            ?.last_name
                                                    }
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[8%]">
                                                <p className="font-KalamehMed text-sm font-medium">
                                                    {
                                                        project?.selected_user
                                                            ?.first_name
                                                    }{" "}
                                                    {
                                                        project?.selected_user
                                                            ?.last_name
                                                    }
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[12%]">
                                                <p className="font-KalamehMed text-sm font-medium">
                                                    <p className="font-KalamehMed text-sm font-medium">
                                                        {project?.expertise}{" "}
                                                        {" : "}
                                                        {project?.sub_expertise?.map(
                                                            (item, index) => {
                                                                return (
                                                                    item +
                                                                    (index !==
                                                                    project
                                                                        ?.sub_expertise
                                                                        ?.length -
                                                                        1
                                                                        ? " _ "
                                                                        : "")
                                                                );
                                                            }
                                                        )}
                                                    </p>
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[8%]">
                                                <p className="font-KalamehMed text-sm font-medium mr-2">
                                                    {project?.offer_time}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[8%]">
                                                <p className="font-KalamehMed text-sm font-medium mr-2">
                                                    {project?.final_time}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[8%]">
                                                <p className="font-KalamehMed text-sm font-medium mr-2">
                                                    {Number(
                                                        project?.offer_price
                                                    ).toLocaleString()}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[8%]">
                                                <p className="font-KalamehMed text-sm font-medium mr-2">
                                                    {Number(
                                                        project?.final_price
                                                    ).toLocaleString()}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[12%]">
                                                <div className="flex items-center">
                                                    <p className="font-KalamehMed text-sm font-medium mr-2">
                                                        {project?.createdAt}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-span-1 w-[12%]">
                                                <div className="flex items-center">
                                                    <p
                                                        className={`font-KalamehMed text-sm font-medium ${
                                                            project?.status ===
                                                            "accept"
                                                                ? "text-green-300"
                                                                : project?.status ===
                                                                      "pre_payment" ||
                                                                  project?.status ===
                                                                      "paid"
                                                                ? "text-yellow-300"
                                                                : project?.status ===
                                                                      "accepted" ||
                                                                  project?.status ===
                                                                      "done" ||
                                                                  project?.status ===
                                                                      "ongoing"
                                                                ? "text-green-300"
                                                                : "text-red-300"
                                                        }`}
                                                    >
                                                        {project?.status ===
                                                        "pre_payment"
                                                            ? "پرداخت نشده"
                                                            : project?.status ===
                                                              "paid"
                                                            ? "در دست بررسی"
                                                            : project?.status ===
                                                              "accepted"
                                                            ? "منتشر شده"
                                                            : project?.status ===
                                                              "rejected"
                                                            ? "رد شده"
                                                            : project?.status ===
                                                              "ongoing"
                                                            ? "در دست اقدام"
                                                            : project?.status ===
                                                              "done"
                                                            ? "انجام شده"
                                                            : "کنسل شده"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-span-1 w-[8%]">
                                                <p className="font-KalamehMed text-sm font-medium">
                                                    <button
                                                        onClick={() => {
                                                            navigate(
                                                                `${project?._id}`
                                                            );
                                                        }}
                                                        className="bg-[#1f5f79] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-5"
                                                    >
                                                        جزئیات
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Projects;
