import React from "react";

function SupportIcon(props) {
  return (
    <svg
      className={props.className}
      {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.56971 22.7001C3.22971 22.7001 1.24971 20.72 1.24971 18.38V12.22C1.19971 9.30005 2.27971 6.55005 4.29971 4.49005C6.31971 2.44005 9.02971 1.30005 11.9497 1.30005C17.9097 1.30005 22.7497 6.15005 22.7497 12.1V18.26C22.7497 20.64 20.8097 22.58 18.4297 22.58C16.0897 22.58 14.1097 20.6 14.1097 18.26V15.45C14.1097 14 15.2497 12.86 16.6997 12.86C18.1497 12.86 19.2897 14 19.2897 15.45V18.48C19.2897 18.89 18.9497 19.23 18.5397 19.23C18.1297 19.23 17.7897 18.89 17.7897 18.48V15.45C17.7897 14.77 17.2397 14.36 16.6997 14.36C16.0197 14.36 15.6097 14.91 15.6097 15.45V18.26C15.6097 19.7901 16.8997 21.08 18.4297 21.08C19.9597 21.08 21.2497 19.7901 21.2497 18.26V12.1C21.2497 6.97005 17.0797 2.80005 11.9497 2.80005C9.43971 2.80005 7.10971 3.77005 5.36971 5.54005C3.62971 7.31005 2.69971 9.68005 2.74971 12.2V18.38C2.74971 19.91 4.03971 21.2001 5.56971 21.2001C7.09971 21.2001 8.38971 19.91 8.38971 18.38V15.57C8.38971 14.89 7.83971 14.48 7.29971 14.48C6.61971 14.48 6.20971 15.03 6.20971 15.57V18.49C6.20971 18.9 5.86971 19.24 5.45971 19.24C5.04971 19.24 4.70971 18.9 4.70971 18.49V15.57C4.70971 14.12 5.84971 12.98 7.29971 12.98C8.74971 12.98 9.88971 14.12 9.88971 15.57V18.38C9.88971 20.72 7.90971 22.7001 5.56971 22.7001Z" />
    </svg>

  );
}

export default SupportIcon;
