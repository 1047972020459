import { RouterProvider } from "react-router-dom";
import AuthProvider from "./utils/AuthProvider";
import { Toaster } from "react-hot-toast";
import { router } from "./routers/appRoutes";

function App() {
  return (
    <div className=" lg:block">
      <AuthProvider isSignedIn={false}>
        <RouterProvider router={router} />
        <Toaster />
      </AuthProvider> 
    </div>
  );
}

export default App;
