import React from "react";
import AdminHeader from "../components/admin-header";
import Sidebar from "../components/sidebar";

const Dashboard = () => {
    return (
        <>
            <AdminHeader />
            <div className="min-h-screen mx-auto">
                <div className="flex h-full w-full">
                    <div className="bg-[#1f5f79]">
                        <Sidebar />
                    </div>
                    <div className="w-full h-full p-4 mb-11 mx-auto bg-[#252525] text-white ">
                        <div className="w-full flex items-center justify-between"></div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Dashboard;
