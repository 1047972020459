import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/admin-header";
import Sidebar from "../../components/sidebar";
import Loading from "../../components/elements/loading";
import { BaseUrl, ImageBaseUrl } from "../../components/BaseUrl";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Shops = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [shopsList, setShopList] = useState([]);
    const getUserList = (filt, page = 1) => {
        setLoading(true);
        const config = {
            url: `${BaseUrl}/shops?limit=20&page=${page}`,
            method: "GET",
        };
        axios(config)
            .then((res) => {
                setShopList(res?.data?.shops);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getUserList();
    }, []);
    return (
        <>
            <AdminHeader />
            <div className="min-h-screen mx-auto">
                <div className="flex h-full w-full">
                    <div className="bg-[#1f5f79]">
                        <Sidebar />
                    </div>
                    <div className="w-full h-full p-4 mb-11 mx-auto bg-[#252525] text-white ">
                        <div className="w-full mt-4 flex items-center bg-[#252525] border-b ">
                            <div className="flex-1 w-full flex items-center h-11 px-5">
                                <div className="col-span-1 w-[16%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        نام و نام خانوادگی کاربر
                                    </p>
                                </div>
                                <div className="col-span-1 w-[15%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        عنوان فروشگاه
                                    </p>
                                </div>
                                <div className="col-span-1  w-[15%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        دسته بندی
                                    </p>
                                </div>
                                <div className="col-span-1  w-[10%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        استان
                                    </p>
                                </div>
                                <div className="col-span-1  w-[10%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        شهر
                                    </p>
                                </div>
                                <div className="col-span-1  w-[10%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        محله
                                    </p>
                                </div>
                                <div className="col-span-1  w-[15%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        تاریخ ایجاد فروشگاه
                                    </p>
                                </div>
                                {/* <div className="col-span-1  w-[14%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        وضعیت
                                    </p>
                                </div> */}
                                <div className="col-span-1 w-[10%]">
                                    <p className="font-KalamehMed text-sm font-medium"></p>
                                </div>
                            </div>
                        </div>
                        {loading ? (
                            <div className="w-full flex items-center justify-center mt-5">
                                <Loading className="w-14 h-14 text-blacklead animate-pulse" />
                            </div>
                        ) : (
                            <div
                                className="overflow-y-scroll hide-scrollbar w-full"
                            // style={{ maxHeight: maxHeightTable }}
                            >
                                {shopsList?.map((shop, index) => (
                                    <div className="w-full mt-4 flex items-center bg-[#252525]">
                                        <div
                                            className={`flex-1 w-full flex items-center h-11 px-5  rounded-lg  py-8 ${index % 2 == 0
                                                ? "bg-[#c0e2f04b]"
                                                : "bg-[#678fa062]"
                                                }`}
                                        >
                                            <div className="col-span-1 w-[16%]">
                                                <div className="flex items-center">
                                                    <img
                                                        className="w-[50px] h-[50px] rounded-md ml-2"
                                                        src={
                                                            ImageBaseUrl +
                                                            shop?.profile_image
                                                        }
                                                        alt=""
                                                    />
                                                    <p className="font-KalamehMed text-sm font-medium">
                                                        {shop?.first_name}{" "}
                                                        {shop?.last_name}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-span-1 w-[15%]">
                                                <p className="font-KalamehMed text-sm font-medium">
                                                    {shop?.vitrine_name}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[15%]">
                                                <p className="font-KalamehMed text-sm font-medium">
                                                    {shop?.category}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[10%]">
                                                <p className="font-KalamehMed text-sm font-medium">
                                                    {shop?.province?.name}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[10%]">
                                                <p className="font-KalamehMed text-sm font-medium">
                                                    {shop?.city?.name}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[10%]">
                                                <p className="font-KalamehMed text-sm font-medium">
                                                    {shop?.neighbourhood}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[15%]">
                                                <p className="font-KalamehMed text-sm font-medium">
                                                    {shop?.createdAt}
                                                </p>
                                            </div>
                                            {/* <div className="col-span-1 w-[14%]">
                                                <div className="flex items-center">
                                                    <p className="font-KalamehMed text-sm font-medium">
                                                        {shop?.blocked
                                                            ? "غیر فعال"
                                                            : "فعال"}
                                                    </p>
                                                    {shop?.blocked ? (
                                                        <button className="bg-[#146820] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-3">
                                                            تایید
                                                        </button>
                                                    ) : (
                                                        <button className="bg-[#a02121d3] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-3">
                                                            غیر فعال
                                                        </button>
                                                    )}
                                                </div>
                                            </div> */}
                                            <div className="col-span-1 w-[10%]">
                                                <p className="font-KalamehMed text-sm font-medium">
                                                    <button
                                                        onClick={() => {
                                                            navigate(
                                                                `${shop?._id}`
                                                            );
                                                        }}
                                                        className="bg-[#1f5f79] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-5"
                                                    >
                                                        جزئیات
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Shops;
