import React, { useState } from "react";
import { BaseUrl, ImageBaseUrl } from "../BaseUrl";
import axios from "axios";
import Modal from "react-modal";
import toast from "react-hot-toast";
const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        position: "fixed",
    },
};
const UserDet = ({ userData, id, getUserDet }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editedData, setEditedData] = useState({});
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalImage, setModalImage] = useState("");

    const openModal = (image) => {
        setModalImage(image);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEditedData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        try {
            const response = await axios.put(
                `${BaseUrl}/users/update?userId=${userData._id}`,
                editedData,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`, // اگر نیاز به توکن احراز هویت دارید
                    },
                }
            );
            setIsEditing(false);
        } catch (error) {
            if (
                error.response &&
                error.response.data &&
                error.response.data.errors
            ) {
                error.response.data.errors.forEach((err) => {
                    if (typeof err.message === "string") {
                        // نمایش پیام خطا در صورتیکه به صورت string باشد
                        toast.error(err.message);
                    } else {
                        // نمایش پیام خطا برای موارد دیگر
                        toast.error("خطای ناشناخته");
                    }
                });
            } else {
                // نمایش پیام خطا برای سایر موارد
                toast.error("خطای ناشناخته");
            }
        }
    };

    const acceptVerify = () => {
        axios
            .post(`${BaseUrl}/users/verify?userId=${id}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then(async (response) => {
                try {
                    toast.success(response?.data?.message);
                    getUserDet();
                } catch (error) {
                    if (error?.response?.data?.error) {
                        toast.error(error.response.data.error);
                    } else {
                        // نمایش پیام خطا برای سایر موارد
                        toast.error("خطای ناشناخته");
                    }
                }
            })
            .catch((error) => {
                toast.show(error?.response?.data?.message, {
                    type: "danger",
                    placement: "top",
                    duration: 4000,
                    offset: 30,
                    animationType: "slide-in",
                });
            });
    };
    const rejectVerify = () => {
        axios
            .post(`${BaseUrl}/users/reject?userId=${id}`, {
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            .then(async (response) => {
                try {
                    toast.success(response?.data?.message);
                    getUserDet();
                } catch (error) {
                    if (error?.response?.data?.error) {
                        toast.error(error.response.data.error);
                    } else {
                        // نمایش پیام خطا برای سایر موارد
                        toast.error("خطای ناشناخته");
                    }
                }
            })
            .catch((error) => {
                toast.show(error?.response?.data?.message, {
                    type: "danger",
                    placement: "top",
                    duration: 4000,
                    offset: 30,
                    animationType: "slide-in",
                });
            });
    };
    return (
        <div className="flex gap-3  ">
            <div className="flex flex-col gap-5 pr-5 mt-5">
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    className="post-image-modal"
                    style={customStyles}
                >
                    {/* استفاده از ImageMagnify */}
                    <img
                        src={modalImage}
                        alt="Post"
                        className="post-modal-image"
                    />
                </Modal>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {/* <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="none"
                        viewBox="0 0 24 24"
                    >
                        <path
                            fill="#00838F"
                            d="M12.12 13.53h-.17c-2.27-.07-3.97-1.84-3.97-4.02 0-2.22 1.81-4.03 4.03-4.03s4.03 1.81 4.03 4.03a4.014 4.014 0 01-3.89 4.02h-.03zM12 6.97c-1.4 0-2.53 1.14-2.53 2.53a2.52 2.52 0 002.43 2.53.84.84 0 01.23 0 2.535 2.535 0 002.4-2.53c0-1.39-1.13-2.53-2.53-2.53zM12 22.75c-2.69 0-5.26-1-7.25-2.82a.755.755 0 01-.24-.63c.13-1.19.87-2.3 2.1-3.12 2.98-1.98 7.81-1.98 10.78 0 1.23.83 1.97 1.93 2.1 3.12.03.24-.06.47-.24.63A10.71 10.71 0 0112 22.75zM6.08 19.1A9.208 9.208 0 0012 21.25c2.17 0 4.26-.76 5.92-2.15-.18-.61-.66-1.2-1.37-1.68-2.46-1.64-6.63-1.64-9.11 0-.71.48-1.18 1.07-1.36 1.68z"
                        ></path>
                        <path
                            fill="#00838F"
                            d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12S6.07 1.25 12 1.25 22.75 6.07 22.75 12 17.93 22.75 12 22.75zm0-20C6.9 2.75 2.75 6.9 2.75 12S6.9 21.25 12 21.25s9.25-4.15 9.25-9.25S17.1 2.75 12 2.75z"
                        ></path>
                    </svg> */}
                        تصویر پروفایل:
                    </div>
                    <div className="flex items-center font-medium  font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 p-4">
                        <img
                            onClick={() => {
                                openModal(
                                    ImageBaseUrl + userData?.profile_image
                                );
                            }}
                            src={ImageBaseUrl + userData?.profile_image}
                            className="w-[200px] h-[200px] rounded-md"
                            alt=""
                        />
                    </div>
                </div>
                {/* User Name */}
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        نام کاربری:
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {isEditing ? (
                            <input
                                type="text"
                                name="user_name"
                                defaultValue={userData.user_name}
                                onChange={handleChange}
                                className="bg-[#222] text-primary px-2 py-1 rounded-md w-full min-h-9"
                            />
                        ) : (
                            userData?.user_name
                        )}
                    </div>
                </div>

                {/* First Name */}
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        نام:
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {isEditing ? (
                            <input
                                type="text"
                                name="first_name"
                                defaultValue={userData.first_name}
                                onChange={handleChange}
                                className="bg-[#222] text-primary px-2 py-1 rounded-md w-full min-h-9"
                            />
                        ) : (
                            userData?.first_name
                        )}
                    </div>
                </div>

                {/* Last Name */}
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        نام خانوادگی:
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {isEditing ? (
                            <input
                                type="text"
                                name="last_name"
                                defaultValue={userData.last_name}
                                onChange={handleChange}
                                className="bg-[#222] text-primary px-2 py-1 rounded-md w-full min-h-9"
                            />
                        ) : (
                            userData?.last_name
                        )}
                    </div>
                </div>

                {/* Mobile */}
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        موبایل:
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {isEditing ? (
                            <input
                                type="text"
                                name="mobile"
                                defaultValue={userData.mobile}
                                onChange={handleChange}
                                className="bg-[#222] text-primary px-2 py-1 rounded-md w-full min-h-9"
                            />
                        ) : (
                            userData?.mobile
                        )}
                    </div>
                </div>
                {/* National Code */}
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        کد ملی:
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {isEditing ? (
                            <input
                                type="text"
                                name="national_code"
                                defaultValue={userData.national_code}
                                onChange={handleChange}
                                className="bg-[#222] text-primary px-2 py-1 rounded-md w-full min-h-9"
                            />
                        ) : (
                            userData?.national_code
                        )}
                    </div>
                </div>

                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        استان:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {userData?.province?.name}
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        شهر:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {userData?.city?.name}
                    </div>
                </div>
                {/* Address */}
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        آدرس:
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {isEditing ? (
                            <input
                                type="text"
                                name="address"
                                defaultValue={userData.address}
                                onChange={handleChange}
                                className="bg-[#222] text-primary px-2 py-1 rounded-md w-full min-h-9"
                            />
                        ) : (
                            userData?.address
                        )}
                    </div>
                </div>
                {/* Height */}
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        قد:
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {isEditing ? (
                            <input
                                type="text"
                                name="height"
                                defaultValue={userData.height}
                                onChange={handleChange}
                                className="bg-[#222] text-primary px-2 py-1 rounded-md w-full min-h-9"
                            />
                        ) : (
                            userData?.height
                        )}
                    </div>
                </div>

                {/* Weight */}
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        وزن:
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {isEditing ? (
                            <input
                                type="text"
                                name="weight"
                                defaultValue={userData.weight}
                                onChange={handleChange}
                                className="bg-[#222] text-primary px-2 py-1 rounded-md w-full min-h-9"
                            />
                        ) : (
                            userData?.weight
                        )}
                    </div>
                </div>
                {/* Size */}
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        سایز:
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {isEditing ? (
                            <input
                                type="text"
                                name="size"
                                defaultValue={userData.size}
                                onChange={handleChange}
                                className="bg-[#222] text-primary px-2 py-1 rounded-md w-full min-h-9"
                            />
                        ) : (
                            userData?.size
                        )}
                    </div>
                </div>
                {/* Bio */}
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        بیو:
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {isEditing ? (
                            <input
                                type="text"
                                name="bio"
                                defaultValue={userData.bio}
                                onChange={handleChange}
                                className="bg-[#222] text-primary px-2 py-1 rounded-md w-full min-h-9"
                            />
                        ) : (
                            userData?.bio
                        )}
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        تاریخ تولد:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {userData?.birthday}
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        خارج از محدوده سکونت به من پیشنهاد بده:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {userData?.cooperation_abroad == true ? "بله" : "خیر"}
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        پروژه با روابط عمومی بالا:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {userData?.conversation_projects == true
                            ? "بله"
                            : "خیر"}
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        تخصص:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {userData?.expertise} {" : "}
                        {userData?.sub_expertise?.map((item, index) => {
                            return (
                                item +
                                (index !== userData?.sub_expertise?.length - 1
                                    ? " _ "
                                    : "")
                            );
                        })}
                    </div>
                </div>
                {/* Shaba */}
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        شماره شبا:
                    </div>
                    <div className="flex items-center font-medium min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {isEditing ? (
                            <input
                                type="text"
                                name="shaba"
                                defaultValue={userData.shaba}
                                onChange={handleChange}
                                className="bg-[#222] text-primary px-2 py-1 rounded-md w-full min-h-9"
                            />
                        ) : (
                            userData?.shaba
                        )}
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        تصویر کارت ملی:
                    </div>
                    <div className="flex items-center font-medium  font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 p-4">
                        <img
                            onClick={() => {
                                openModal(
                                    ImageBaseUrl + userData?.national_card_image
                                );
                            }}
                            src={ImageBaseUrl + userData?.national_card_image}
                            className="w-[200px] h-[200px] rounded-md"
                            alt=""
                        />
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        آخرین زمان ورود:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {userData?.last_online}
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        تاریخ ثبت نام:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {userData?.createdAt}
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        وضعیت مدارک:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        <p className="font-KalamehMed text-sm font-medium">
                            {userData?.is_verified === "verified"
                                ? "تایید شده"
                                : userData?.is_verified === "pending"
                                ? "در انتظار تایید"
                                : ""}
                        </p>
                        <button
                            onClick={() => acceptVerify()}
                            className="bg-[#146820] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-3"
                        >
                            تایید مدارک
                        </button>
                        <button
                            onClick={() => rejectVerify()}
                            className="bg-[#a02121d3] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-3"
                        >
                            رد مدارک
                        </button>
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        امتیاز کاربر:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {userData?.rate}
                    </div>
                </div>
                <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        سطح کاربر:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {userData?.user_level}
                    </div>
                </div>
                {/* <div className="flex items-center w-full">
                    <div className="w-[180px] ml-3 flex items-center font-medium font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        لوکیشن:
                    </div>
                    <div className="flex items-center font-medium  min-w-[500px] font-KalamehMed text-sm text-primary rounded-lg bg-[#111] min-h-14 px-4">
                        {userData?.profile_image}
                    </div>
                </div> */}

                {/* Save Button */}
                <div className="flex items-center w-full">
                    {isEditing ? (
                        <button
                            onClick={handleSave}
                            className="bg-[#146820] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300"
                        >
                            ذخیره
                        </button>
                    ) : (
                        <button
                            onClick={() => setIsEditing(!isEditing)}
                            className="bg-[#146820] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-3"
                        >
                            ویرایش
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default UserDet;
