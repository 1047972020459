import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "./elements/loading";
import { Link } from "react-router-dom/dist";

function AdminHeader() {
    const navigate = useNavigate();
    const handleLogOut = () => {
        localStorage.clear();
        document.getElementById("to_login").click();
    };
    function refreshPage() {
        setTimeout(() => {
            window.location.reload(false);
        }, 1000);
    }
    return (
        <>
            <Link
                to={{ pathname: "/login" }}
                id="to_login"
                onClick={refreshPage}
            ></Link>
            <div className="z-[52] sticky mx-auto   top-0 w-full h-[65px]  bg-[#1f5f79] py-2 px-5">
                <div className="  mx-auto flex justify-between items-center">
                    <div className="flex flex-row items-center gap-[50px]">
                        <div className="flex cursor-pointer bg-primary group transition-colors duration-500 hover:bg-cyann items-center h-[51px] rounded-[4px] font-Kalameh text-lg min-w-[250px]">
                            <div className="w-[35px] h-[35px] border-[2px] border-[#1f5f79] rounded-full ml-3 flex items-center justify-center overflow-hidden ">
                                <img
                                    // src={avatar}
                                    className="w-full h-full object-cover"
                                    alt=""
                                />
                            </div>
                            <h4 className="group-hover:text-white transition-colors duration-500 text-white">
                                {/* {full_name} */}
                            </h4>
                        </div>
                    </div>
                    <div className="flex items-center relative">
                        <div
                            // onClick={() => logOutMutation.mutate()}
                            className="flex bg-white cursor-pointer bg-primary hover:bg-[#cbade4] px-5 items-center h-9 group transition-colors duration-500 hover:bg-cyann rounded-[4px] font-KalamehMed font-medium"
                        >
                            <>
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8.8999 7.55999C9.2099 3.95999 11.0599 2.48999 15.1099 2.48999H15.2399C19.7099 2.48999 21.4999 4.27999 21.4999 8.74999V15.27C21.4999 19.74 19.7099 21.53 15.2399 21.53H15.1099C11.0899 21.53 9.2399 20.08 8.9099 16.54"
                                        stroke="#240B39"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M15.0001 12H3.62012"
                                        stroke="#240B39"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                    <path
                                        d="M5.85 8.65002L2.5 12L5.85 15.35"
                                        stroke="#240B39"
                                        stroke-width="1.5"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    />
                                </svg>
                                <button className="mr-3 group-hover: transition-colors duration-500 text-[#240B39">
                                    خروج
                                </button>
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminHeader;
