import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/admin-header";
import Sidebar from "../../components/sidebar";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../../components/BaseUrl";
import axios from "axios";
import Loading from "../../components/elements/loading";

const Tickets = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [ticketList, setTicketList] = useState([]);
    const getTicketList = (filt, page = 1) => {
        setLoading(true);
        const config = {
            url: `${BaseUrl}/tickets?limit=20&page=${page}`,
            method: "GET",
        };
        axios(config)
            .then((res) => {
                setTicketList(res?.data?.tickets);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getTicketList();
    }, []);
    return (
        <>
            <AdminHeader />
            <div className="min-h-screen mx-auto">
                <div className="flex h-full w-full">
                    <div className="bg-[#1f5f79]">
                        <Sidebar />
                    </div>
                    <div className="w-full h-full p-4 mb-11 mx-auto bg-[#252525] text-white ">
                        <div className="w-full mt-4 flex items-center bg-[#252525] border-b ">
                            <div className="flex-1 w-full flex items-center h-11 px-5">
                                <div className="col-span-1 w-[16%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        نام کاربری
                                    </p>
                                </div>
                                <div className="col-span-1 w-[16%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        نوع کاربری
                                    </p>
                                </div>
                                <div className="col-span-1  w-[16%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        نام و نام خانوادگی
                                    </p>
                                </div>
                                <div className="col-span-1  w-[14%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        موبایل
                                    </p>
                                </div>
                                <div className="col-span-1  w-[14%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        تاریخ ثبت تیکت
                                    </p>
                                </div>
                                <div className="col-span-1  w-[12%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        وضعیت
                                    </p>
                                </div>
                                <div className="col-span-1 w-[12%]">
                                    <p className="font-KalamehMed text-sm font-medium"></p>
                                </div>
                            </div>
                        </div>
                        {loading ? (
                            <div className="w-full flex items-center justify-center mt-5">
                                <Loading className="w-14 h-14 text-blacklead animate-pulse" />
                            </div>
                        ) : (
                            <div
                                className="overflow-y-scroll hide-scrollbar w-full"
                            // style={{ maxHeight: maxHeightTable }}
                            >
                                {ticketList?.map((ticket, index) => (
                                    <div className="w-full mt-4 flex items-center bg-[#252525]">
                                        <div
                                            className={`flex-1 w-full flex items-center h-[90px] px-5  rounded-lg  py-8 ${index % 2 == 0
                                                ? "bg-[#c0e2f04b]"
                                                : "bg-[#678fa062]"
                                                }`}
                                        >
                                            <div className="col-span-1 w-[16%]">
                                                <p className="font-KalamehMed text-sm font-medium mr-2">
                                                    {ticket?.user?.user_name}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[16%]">
                                                <p className="font-KalamehMed text-sm font-medium mr-2">
                                                    {ticket?.user?.expertise}

                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[16%]">
                                                <p className="font-KalamehMed text-sm font-medium mr-2">
                                                    {ticket?.user?.first_name}{" "}{ticket?.user?.last_name}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[14%]">
                                                <p className="font-KalamehMed text-sm font-medium mr-2">
                                                    {ticket?.user?.mobile}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[14%]">
                                                <div className="flex items-center">
                                                    <p className="font-KalamehMed text-sm font-medium mr-2">
                                                        {ticket?.createdAt}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-span-1 w-[12%]">
                                                <div className="flex items-center">
                                                    <p className={`font-KalamehMed text-sm font-medium ${ticket?.status === "Pending" ?
                                                        "text-yellow-300" :
                                                        ticket?.status === "Answered" ?
                                                            "text-green-300" :
                                                            ticket?.status === "Customer Response" ?
                                                                "text-yellow-300" : "text-red-300"}`}>
                                                        {ticket?.status === "Pending" ?
                                                            "در انتظار پاسخ" :
                                                            ticket?.status === "Answered" ?
                                                                "پاسخ داده شده" :
                                                                ticket?.status === "Customer Response" ?
                                                                    "پاسخ مشتری" : "بسته شده"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-span-1 w-[12%]">
                                                <p className="font-KalamehMed text-sm font-medium">
                                                    <button
                                                        onClick={() => {
                                                            navigate(
                                                                `${ticket?._id}/${ticket?.status}`,
                                                            );
                                                        }}
                                                        className="bg-[#1f5f79] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-5"
                                                    >
                                                        جزئیات
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div >
        </>
    );
};

export default Tickets;
