import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/admin-header";
import Sidebar from "../../components/sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { BaseUrl, ImageBaseUrl } from "../../components/BaseUrl";
import axios from "axios";
import Loading from "../../components/elements/loading";
import Modal from 'react-modal';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        position: "fixed",
    },
};
const TicketDetail = () => {
    const param = useParams();
    console.log(param);
    const ticketId = param?.id;
    const status = param?.status
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [messageList, setMessageList] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalImage, setModalImage] = useState('');

    const openModal = (image) => {
        setModalImage(image);
        setModalIsOpen(true);
    }

    const closeModal = () => {
        setModalIsOpen(false);
    }
    const getMessageList = (filt, page = 1) => {
        setLoading(true);
        const config = {
            url: `${BaseUrl}/tickets/messages?ticketId=${ticketId}&page=${page}&limit=20`,
            method: "GET",
        };
        axios(config)
            .then((res) => {
                setMessageList(res?.data?.messages);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getMessageList();
    }, []);
    const handleSendMessage = () => {
        if (!newMessage.trim()) return;

        const config = {
            url: `${BaseUrl}/tickets/message`,
            method: "POST",
            data: {
                ticketId,
                text: newMessage,
            },
        };
        axios(config)
            .then((res) => {
                setNewMessage("");
                getMessageList();
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const handleCloseTicket = () => {
        const config = {
            url: `${BaseUrl}/tickets/close`,
            method: "POST",
            data: { ticketId },
        };
        axios(config)
            .then((res) => {
                navigate(-1);
            })
            .catch((err) => {
                console.error(err);
            });
    }

    return (
        <>
            <AdminHeader />
            <div className="min-h-screen mx-auto flex flex-col">
                <Modal
                    isOpen={modalIsOpen}
                    onRequestClose={closeModal}
                    className="post-image-modal"
                    style={customStyles}
                >
                    <div className="bg-white p-10 rounded-xl">
                        <h2>آیا مطمئنید میخواهید تیکت را ببندید ؟</h2>
                        <div className="flex mt-10">
                            <button className="w-[120px] h-[37px] rounded-lg bg-red-500 ml-3">خیر</button>
                            <button className="w-[120px] h-[37px] rounded-lg bg-green-500" onClick={handleCloseTicket}>بله</button>
                        </div>
                    </div>
                </Modal>
                <div className="flex flex-1 h-full w-full">
                    <div className="bg-[#1f5f79]">
                        <Sidebar />
                    </div>
                    <div className="w-full h-full p-4 mb-11 mx-auto bg-[#252525] text-white relative">
                        {loading ? (
                            <Loading />
                        ) : (
                            <div className="space-y-4 overflow-y-auto pb-[200px]">
                                {messageList.map((message) => (
                                    <div
                                        key={message._id}
                                        className={`p-4 rounded-lg max-w-md ${message.senderType === "Admin" ? "bg-blue-500 text-white self-start" : "bg-gray-700 text-white self-end"
                                            }`}
                                    >
                                        <div className="font-bold">
                                            {message.senderType === "Admin" ? "Admin" : `${message.senderId?.first_name} ${message.senderId?.last_name}`}
                                        </div>
                                        <div className="text-sm">{message.createdAt}</div>
                                        <div className="mt-2">{message.text}</div>
                                        {message.file && (
                                            <img src={ImageBaseUrl + message.file} alt="" className="max-w-[400px]" />
                                        )}
                                    </div>
                                ))}
                            </div>
                        )}
                        <div className="fixed bottom-0 left-0 right-0 p-4 bg-[#1f1f1f] flex items-center flex-col">
                            <span className="mb-3 text-red-500 text-xl">
                                {
                                    status && status == "Closed" && "تیکت بسته شده است"
                                }
                            </span>
                            {
                                status !== "Closed" &&
                                <>

                                    <textarea
                                        className="flex-grow p-2 bg-gray-800 text-white min-h-[80px] rounded-xl w-full"
                                        rows="1"
                                        value={newMessage}
                                        onChange={(e) => setNewMessage(e.target.value)}
                                        placeholder="بنویسید ..."
                                    ></textarea>
                                    <div className="flex">
                                        <button
                                            className=" p-2 bg-blue-500 text-white mt-4 min-w-[200px] rounded-xl"
                                            onClick={handleSendMessage}
                                        >
                                            ارسال
                                        </button>
                                        <button
                                            className=" p-2 bg-red-500 text-white mt-4 min-w-[200px] rounded-xl mr-3"
                                            onClick={openModal}
                                        >
                                            بستن تیکت
                                        </button>

                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TicketDetail;
