import React from "react";

function ProjectsIcon(props) {
  return (
    <svg
      className={props.className}
      {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15 12.95H8C7.59 12.95 7.25 12.61 7.25 12.2C7.25 11.79 7.59 11.45 8 11.45H15C15.41 11.45 15.75 11.79 15.75 12.2C15.75 12.61 15.41 12.95 15 12.95Z" />
      <path d="M12.38 16.95H8C7.59 16.95 7.25 16.61 7.25 16.2C7.25 15.79 7.59 15.45 8 15.45H12.38C12.79 15.45 13.13 15.79 13.13 16.2C13.13 16.61 12.79 16.95 12.38 16.95Z" />
      <path d="M14 6.75H10C9.04 6.75 7.25 6.75 7.25 4C7.25 1.25 9.04 1.25 10 1.25H14C14.96 1.25 16.75 1.25 16.75 4C16.75 4.96 16.75 6.75 14 6.75ZM10 2.75C9.01 2.75 8.75 2.75 8.75 4C8.75 5.25 9.01 5.25 10 5.25H14C15.25 5.25 15.25 4.99 15.25 4C15.25 2.75 14.99 2.75 14 2.75H10Z" />
      <path d="M15 22.75H9C3.38 22.75 2.25 20.17 2.25 16V9.99999C2.25 5.43999 3.9 3.48999 7.96 3.27999C8.36 3.25999 8.73 3.56999 8.75 3.98999C8.77 4.40999 8.45 4.74999 8.04 4.76999C5.2 4.92999 3.75 5.77999 3.75 9.99999V16C3.75 19.7 4.48 21.25 9 21.25H15C19.52 21.25 20.25 19.7 20.25 16V9.99999C20.25 5.77999 18.8 4.92999 15.96 4.76999C15.55 4.74999 15.23 4.38999 15.25 3.97999C15.27 3.56999 15.63 3.24999 16.04 3.26999C20.1 3.48999 21.75 5.43999 21.75 9.98999V15.99C21.75 20.17 20.62 22.75 15 22.75Z" />
    </svg>

  );
}

export default ProjectsIcon;
