import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/admin-header";
import Sidebar from "../../components/sidebar";
import { useNavigate } from "react-router-dom";
import { BaseUrl } from "../../components/BaseUrl";
import axios from "axios";
import Loading from "../../components/elements/loading";

const Financials = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [financialList, setFinancialList] = useState([]);
    const getFinancialList = (filt, page = 1) => {
        setLoading(true);
        const config = {
            url: `${BaseUrl}/financial?limit=20&page=${page}`,
            method: "GET",
        };
        axios(config)
            .then((res) => {
                setFinancialList(res?.data?.financials);
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
            });
    };
    useEffect(() => {
        getFinancialList();
    }, []);
    return (
        <>
            <AdminHeader />
            <div className="min-h-screen mx-auto">
                <div className="flex h-full w-full">
                    <div className="bg-[#1f5f79]">
                        <Sidebar />
                    </div>
                    <div className="w-full h-full p-4 mb-11 mx-auto bg-[#252525] text-white ">
                        <div className="w-full mt-4 flex items-center bg-[#252525] border-b ">
                            <div className="flex-1 w-full flex items-center h-11 px-5">
                                <div className="col-span-1 w-[13%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        نام کاربری پرداخت کننده
                                    </p>
                                </div>
                                <div className="col-span-1  w-[13%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        نام و نام خانوادگی
                                    </p>
                                </div>
                                <div className="col-span-1 w-[13%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        عنوان پروژه
                                    </p>
                                </div>
                                <div className="col-span-1  w-[13%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        مبلغ پرداخت  شده
                                    </p>
                                </div>
                                <div className="col-span-1  w-[13%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        نوبت پرداخت
                                    </p>
                                </div>
                                <div className="col-span-1  w-[12%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        وضعیت پرداختی
                                    </p>
                                </div>
                                <div className="col-span-1  w-[12%]">
                                    <p className="font-KalamehMed text-sm font-medium">
                                        تاریخ پرداخت
                                    </p>
                                </div>
                                <div className="col-span-1 w-[11%]">
                                    <p className="font-KalamehMed text-sm font-medium"></p>
                                </div>
                            </div>
                        </div>
                        {loading ? (
                            <div className="w-full flex items-center justify-center mt-5">
                                <Loading className="w-14 h-14 text-blacklead animate-pulse" />
                            </div>
                        ) : (
                            <div
                                className="overflow-y-scroll hide-scrollbar w-full"
                            // style={{ maxHeight: maxHeightTable }}
                            >
                                {financialList?.map((financial, index) => (
                                    <div className="w-full mt-4 flex items-center bg-[#252525]">
                                        <div
                                            className={`flex-1 w-full flex items-center h-[90px] px-5  rounded-lg  py-8 ${index % 2 == 0
                                                ? "bg-[#c0e2f04b]"
                                                : "bg-[#678fa062]"
                                                }`}
                                        >
                                            <div className="col-span-1 w-[13%]">
                                                <p className="font-KalamehMed text-sm font-medium mr-2">
                                                    {financial?.user_id?.user_name}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[13%]">
                                                <p className="font-KalamehMed text-sm font-medium mr-2">
                                                    {financial?.user_id?.first_name}
                                                    {" "}
                                                    {financial?.user_id?.last_name}

                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[13%]">
                                                <p className="font-KalamehMed text-sm font-medium mr-2">
                                                    {financial?.project_id?.title}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[13%]">
                                                <p className="font-KalamehMed text-sm font-medium mr-2">
                                                    {Number(financial?.amount).toLocaleString()}
                                                </p>
                                            </div>
                                            <div className="col-span-1 w-[13%]">
                                                <div className="flex items-center">
                                                    <p className="font-KalamehMed text-sm font-medium mr-2">
                                                        {financial?.type === "create" ?
                                                            financial?.project_id?.project_type == "normal" ?
                                                                "ایجاد پروژه _ معمولی" : financial?.project_id?.project_type == "force" ?
                                                                    "ایجاد پروژه _ فوری" : "ایجاد پروژه _ هایلایت"
                                                            :
                                                            financial?.type === "installment" ?
                                                                financial?.installment_step == "1" ?
                                                                    "پرداخت بخش اول" :
                                                                    financial?.installment_step == "2" ?
                                                                        "پرداخت بخش دوم" :
                                                                        financial?.installment_step == "3" ?
                                                                            "پرداخت بخش سوم" :
                                                                            financial?.installment_step == "all" ?
                                                                                "پرداخت یکجای بخش 2 و 3" : ""
                                                                : ""
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-span-1 w-[12%]">
                                                <div className="flex items-center">
                                                    <p className={`font-KalamehMed text-sm font-medium ${financial?.status === "successful" ?
                                                        "text-green-400" :
                                                        "text-red-400"}`}>
                                                        {financial?.status === "successful" ?
                                                            "موفق" :
                                                            "ناموفق"}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-span-1 w-[12%]">
                                                <div className="flex items-center">
                                                    <p className={`font-KalamehMed text-sm font-medium`}>
                                                        {financial?.createdAt}

                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-span-1 w-[11%]">
                                                <p className="font-KalamehMed text-sm font-medium">
                                                    <button
                                                        onClick={() => {
                                                            navigate(
                                                                `${financial?._id}`,
                                                            );
                                                        }}
                                                        className="bg-[#1f5f79] hover:bg-[#247392] px-8 h-[35px] rounded-md duration-300 mr-5"
                                                    >
                                                        جزئیات
                                                    </button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div >
        </>
    );
};

export default Financials;
